import { Divider, IconButton, Link, Tooltip, Typography } from "@mui/material";

import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import CreateDialog from "../../components/dialogs/CreateDialog";
import { DepthChart } from "../../api/types";
import { DepthChartActionMenu } from "./DepthChartActionMenu";
import { FieldIcon } from "../../components/CustomIcons";
import { SCOUTING_DEPTH_ROUTE } from "../../routes";
import { SEARCH_PARAMS_DEPTH_CHART_ID } from "../../constants";
import { Stack } from "@mui/system";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "../TreeItem";
import { useCreateDepthChart } from "../../api/mutations";
import { useDepthChartContext } from "../../contexts/DepthChartContext";
import { useDepthCharts } from "../../api/queries";
import { useNavigate } from "react-router-dom";
import { useOktaAuthContext } from "../../contexts/OktaAuthContext";
import { useState } from "react";

export default function DepthChartMenu() {
  const { email } = useOktaAuthContext();
  const navigate = useNavigate();
  const { setDepthChartInContext, setIsLoadingDepthChart } =
    useDepthChartContext();

  // QUERIES
  const depthCharts = useDepthCharts();

  // MUTATIONS
  const createDepthChart = useCreateDepthChart();

  // STATES
  const [createDepthChartModalOpen, setCreateFolderModalOpen] = useState(false);

  // USEEFFECTS

  return (
    <>
      <CreateDialog
        key={"create-depth-chart-dialog"}
        open={createDepthChartModalOpen}
        rows={1}
        title="Create Depth Chart"
        label="Depth Chart Name"
        setOpen={setCreateFolderModalOpen}
        yesAction={(label: string, isPublic: boolean) => {
          createDepthChart.mutate({ label, is_public: isPublic });
          setCreateFolderModalOpen(false);
        }}
      />
      <TreeItem
        itemId="DEPTH_CHARTS"
        label={
          <Stack direction="row" display="flex" justifyContent="space-between">
            <Typography fontWeight={500}>DEPTH CHARTS</Typography>
            <Tooltip title="Create Depth Chart" placement="right">
              <IconButton
                color="primary"
                onClick={(event) => {
                  setCreateFolderModalOpen(true);
                  event.stopPropagation();
                }}
                size="small"
                sx={{
                  padding: 0,
                }}
              >
                <AddBoxTwoToneIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      >
        <Divider />

        {depthCharts.data?.length === 0 && (
          <Typography variant="caption">
            No depth charts,{" "}
            <Link
              onClick={() => setCreateFolderModalOpen(true)}
              sx={{ textOverflow: "ellipsis", cursor: "pointer" }}
            >
              create one!
            </Link>
          </Typography>
        )}
        <SimpleTreeView>
          <TreeItem itemId="public" label="Public">
            {depthCharts.isFetched &&
              depthCharts.data &&
              depthCharts.data.map(
                (dc: DepthChart) =>
                  dc.is_public === true &&
                  dc.share_with_email !== email && (
                    <TreeItem
                      key={dc.label}
                      itemId={dc.label || ""}
                      icon={() => <FieldIcon />}
                      label={
                        <Stack
                          key={`${dc.label}-treeview-tree-item-depth-chart-label`}
                          direction="row"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Tooltip
                            key={`${dc.label}-tooltip`}
                            placement="right"
                            title={`${dc.label}`}
                          >
                            <Typography key={dc.label} variant="body2">
                              {dc.label}
                            </Typography>
                          </Tooltip>
                          {dc.owner === email && (
                            <DepthChartActionMenu depthChart={dc} />
                          )}
                        </Stack>
                      }
                      onClick={() => {
                        if (dc.id) {
                          setDepthChartInContext(dc);
                          setIsLoadingDepthChart(true);
                          navigate(
                            `${SCOUTING_DEPTH_ROUTE}?${SEARCH_PARAMS_DEPTH_CHART_ID}=${dc.id?.toString()}`
                          );
                        }
                      }}
                    />
                  )
              )}
          </TreeItem>
          <TreeItem itemId="private" label="Private">
            {depthCharts.isFetched &&
              depthCharts.data &&
              depthCharts.data.map(
                (dc: DepthChart) =>
                  dc.is_public === false &&
                  dc.owner === email && (
                    <TreeItem
                      key={dc.label}
                      itemId={dc.label || ""}
                      icon={() => <FieldIcon />}
                      label={
                        <Stack
                          key={`${dc.label}-treeview-tree-item-depth-chart-label`}
                          direction="row"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Tooltip
                            key={`${dc.label}-tooltip`}
                            placement="right"
                            title={`${dc.label}`}
                          >
                            <Typography key={dc.label} variant="body2">
                              {dc.label}
                            </Typography>
                          </Tooltip>
                          {dc.owner === email && (
                            <DepthChartActionMenu depthChart={dc} />
                          )}
                        </Stack>
                      }
                      onClick={() => {
                        if (dc.id) {
                          setDepthChartInContext(dc);
                          setIsLoadingDepthChart(true);
                          navigate(
                            `${SCOUTING_DEPTH_ROUTE}?${SEARCH_PARAMS_DEPTH_CHART_ID}=${dc.id?.toString()}`
                          );
                        }
                      }}
                    />
                  )
              )}
          </TreeItem>
          <TreeItem itemId="grid" label="Shared With Me">
            {depthCharts.isFetched &&
              depthCharts.data &&
              depthCharts.data.map(
                (dc: DepthChart) =>
                  dc.share_with_email === email && (
                    <TreeItem
                      key={dc.label}
                      itemId={dc.label || ""}
                      icon={() => <FieldIcon />}
                      label={
                        <Stack
                          key={`${dc.label}-treeview-tree-item-depth-chart-label`}
                          direction="row"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Tooltip
                            key={`${dc.label}-tooltip`}
                            placement="right"
                            title={`${dc.label}`}
                          >
                            <Typography key={dc.label} variant="body2">
                              {dc.label}
                            </Typography>
                          </Tooltip>

                          {dc.owner === email && (
                            <DepthChartActionMenu depthChart={dc} />
                          )}
                        </Stack>
                      }
                      onClick={() => {
                        if (dc.id) {
                          setDepthChartInContext(dc);
                          setIsLoadingDepthChart(true);
                          navigate(
                            `${SCOUTING_DEPTH_ROUTE}?${SEARCH_PARAMS_DEPTH_CHART_ID}=${dc.id?.toString()}`
                          );
                        }
                      }}
                    />
                  )
              )}
          </TreeItem>
        </SimpleTreeView>
      </TreeItem>
    </>
  );
}
