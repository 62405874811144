import { createContext, useContext, useState } from "react";

import UploadFileDialog from "../components/dialogs/UploadFileDialog";

interface UploadFileDialogProps {
  handleUploadFileDialogClick: () => void;
}

const defaultState = {
  handleUploadFileDialogClick: () => {},
};
const UploadFileDialogContext =
  createContext<UploadFileDialogProps>(defaultState);

export const UploadFileDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [uploadFileDialogOpen, setUploadFileDialogOpen] =
    useState<boolean>(false);

  const handleUploadFileDialogClick = () => {
    setUploadFileDialogOpen(true);
  };

  const defaultValue = {
    handleUploadFileDialogClick: handleUploadFileDialogClick,
  };

  return (
    <UploadFileDialogContext.Provider value={defaultValue}>
      <UploadFileDialog
        open={uploadFileDialogOpen}
        setOpen={setUploadFileDialogOpen}
      />
      {children}
    </UploadFileDialogContext.Provider>
  );
};

export const useUploadFileDialogContext = () =>
  useContext(UploadFileDialogContext);
