import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import { Box } from "@mui/material";
import ScoutingDataGrid from "./ScoutingDataGrid";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

export default function Players() {
  // CONTEXT
  const { height } = useWindowSizeContext();

  // VARIABLES
  const adjHeight = height - APPBAR_HEIGHT_AS_NUM;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: adjHeight,
        maxHeight: adjHeight,
      }}
    >
      <ScoutingDataGrid />
    </Box>
  );
}
