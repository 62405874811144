import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { MatchReport, Player, ReportQualityRating } from "../../api/types";
import { ReportGroupData, ReportQualityData } from "../../constants";
import { useEffect, useState } from "react";

import { BarChart } from "@mui/x-charts";
import { MatchReports } from "./MatchReports";
import { toTitle } from "../../utils/stringManipulation";
import { usePlayerMatchReports } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface PlayerScoutingProps {
  player: Player;
}

export const PlayerScouting = (props: PlayerScoutingProps) => {
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const playerMatchReportsQuery = usePlayerMatchReports(props.player?.id);
  const matchReports = playerMatchReportsQuery.data;

  // STATE
  const [monitorStance, setMonitorStance] = useState(0);
  const [passStance, setPassStance] = useState(0);
  const [signStance, setSignStance] = useState(0);
  const [profileMatchReports, setProfileMatchReports] = useState<
    MatchReport[] | undefined
  >();
  const [profileAverages, setProfileAverages] = useState<any[]>([]);

  // USEEFFECT
  // set available profiles
  useEffect(() => {
    if (matchReports && matchReports.length > 0) {
      // way to get distinct player profiles from all match_reports

      // get the overall stance
      const stanceCount: { [key: string]: number } = {};
      matchReports.forEach((mr) => {
        stanceCount[mr.stance] = (stanceCount[mr.stance] || 0) + 1;
      });
      if (stanceCount["Monitor"]) {
        setMonitorStance(stanceCount["Monitor"]);
      }
      if (stanceCount["Pass"]) {
        setPassStance(stanceCount["Pass"]);
      }
      if (stanceCount["Sign"]) {
        setSignStance(stanceCount["Sign"]);
      }
    }
  }, [matchReports, monitorStance, signStance, passStance]);
  // update profileMatchReports and calculate averages at the group level and quality level
  useEffect(() => {
    if (matchReports) {
      setProfileMatchReports(matchReports);
      // flatten all qualities from each Match Report into a single list to iterate over easier
      const allRatings: ReportQualityRating[] = [];
      matchReports.forEach((mr) => {
        allRatings.push(...mr.ratings);
      });

      // GROUP by quality group
      const groups = allRatings.reduce((gs, r) => {
        (gs[r.quality.group] = gs[r.quality.group] || []).push(r);

        return gs;
      }, {} as { [key: string]: ReportQualityRating[] });

      // first iterate through each group and calculate total averages
      const groupData: ReportGroupData[] = [];
      Object.entries(groups).forEach((g) => {
        let groupRatingSum = 0;
        let groupFilled = 0;

        const groupRatings = g[1];

        groupRatings.forEach((q) => {
          if (q.rating) {
            groupRatingSum += q.rating;
            groupFilled++;
          }
        });

        // build data for each group
        const gData = {} as ReportGroupData;
        gData["group"] = toTitle(g[0]);
        gData["average"] = groupFilled
          ? Math.round((groupRatingSum / groupFilled) * 100) / 100
          : 0;
        gData["filled"] = groupFilled;
        gData["total"] = groupRatings.length;

        // and now GROUP by quality key within the group
        // iterate through each quality key and calculate averages
        const qualities = groupRatings.reduce((rqs, r) => {
          (rqs[r.quality.key] = rqs[r.quality.key] || []).push(r);

          return rqs;
        }, {} as { [key: string]: ReportQualityRating[] });

        const qualityData: ReportQualityData[] = [];
        Object.entries(qualities).forEach((quality) => {
          let qualityRatingSum = 0;
          let qualityFilled = 0;

          quality[1].forEach((q) => {
            if (q.rating) {
              qualityRatingSum += q.rating;
              qualityFilled++;
            }
          });

          const qData = {} as ReportQualityData;
          qData["abb"] = quality[1][0].quality.abbreviation;
          qData["label"] = quality[1][0].quality.label;
          qData["average"] = qualityFilled
            ? Math.round((qualityRatingSum / qualityFilled) * 100) / 100
            : 0;
          qData["filled"] = qualityFilled;
          qData["total"] = quality[1].length;

          qualityData.push(qData);
        });

        // attach quality averages to group
        gData["qualities"] = qualityData;

        groupData.push(gData);
      });

      setProfileAverages(groupData);
    }
  }, [matchReports]);

  return (
    <Box>
      {!isScreenSmall && (
        <Box>
          {
            <>
              <Box
                display={"flex"}
                justifyContent={"left"}
                alignItems="center"
                ml={6}
                sx={{ cursor: "pointer" }}
                minHeight={31}
              ></Box>
            </>
          }

          {(profileMatchReports && profileMatchReports.length >= 5 && (
            <Box display={"flex"} justifyContent={"center"} m={0} p={0}>
              {
                <BarChart
                  height={400}
                  width={1500}
                  dataset={profileAverages}
                  xAxis={[
                    {
                      label: "Quality Groups",
                      scaleType: "band",
                      dataKey: "group",
                    },
                  ]}
                  yAxis={[
                    {
                      max: 6,
                      label: "Average Rating (1-6)",
                      colorMap: {
                        type: "piecewise",
                        thresholds: [2.5, 4],
                        colors: [
                          theme.palette.error.main,
                          theme.palette.warning.main,
                          theme.palette.success.main,
                        ],
                      },
                    },
                  ]}
                  series={[
                    {
                      dataKey: "average",
                    },
                  ]}
                />
              }
            </Box>
          )) || (
            <Box p={6}>
              <Typography textAlign={"center"} color="gray">
                Player must have 5 Match Reports to see total averages
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {profileMatchReports && (
        <>
          {!isScreenSmall && (
            <>
              <Divider sx={{ paddingTop: 4 }}>
                <Typography fontWeight={500}>MATCH REPORTS</Typography>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={500}>Sign: {signStance}</Typography>
                  <Divider
                    sx={{ ml: 2, mr: 2 }}
                    orientation={"vertical"}
                    flexItem
                  />
                  <Typography fontWeight={500}>
                    Monitor: {monitorStance}
                  </Typography>
                  <Divider
                    sx={{ ml: 2, mr: 2 }}
                    orientation={"vertical"}
                    flexItem
                  />
                  <Typography fontWeight={500}>Pass: {passStance}</Typography>
                </Box>
              </Divider>
            </>
          )}
          <Box
            sx={{
              overflow: "auto",
              height: isScreenSmall ? "500px" : "600px",
            }}
          >
            <MatchReports matchReports={profileMatchReports} />
          </Box>
        </>
      )}
    </Box>
  );
};
