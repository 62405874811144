import {
  Box,
  DialogContentText,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Button from "@mui/material/Button";
import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export type CreateDialogProps = {
  open: boolean;
  rows: number;
  title: string;
  label: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  yesAction: (value: string, isPublic: boolean) => void;
};

export default function CreateDialog(props: CreateDialogProps) {
  const [isChecked, setIsChecked] = useState(true);
  const [value, setValue] = useState("");
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleChecked = (event: { target: { checked: boolean } }) => {
    setIsChecked(event.target.checked); // Update checkbox state
  };

  return (
    <Box
      key={"create-dialog-outer-box"}
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 2,
      }}
    >
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        key={`${props.title}-dialog`}
        fullScreen={props.rows !== 1 && isScreenSmall}
        maxWidth="xl"
        PaperProps={{
          width: props.rows === 1 && !isScreenSmall ? "20vw" : "60vw",
          height: "fit-content",
        }}
      >
        <CloseButton
          key={"create-dialog-box-close-btn"}
          position="absolute"
          top={10}
          left={10}
          onClick={() => props.setOpen(false)}
        />
        <DialogTitle
          key={`${props.title}-dialog-title`}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {props.title}
        </DialogTitle>

        <DialogContent
          key={`${props.title}-dialog-content`}
          sx={{
            width: 500,
            maxWidth: "100%",
          }}
        >
          <DialogContentText>
            <TextField
              key={`${props.title}-dialog-textfield`}
              margin="dense"
              id="value"
              label={props.label}
              type="text"
              fullWidth
              variant="standard"
              multiline
              minRows={props.rows}
              value={value}
              onChange={handleChange}
              slotProps={{
                input: {
                  disableUnderline: true,
                },
              }}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions
          key={`${props.title}-dialog-actions`}
          sx={{ borderTop: "1px solid grey" }}
        >
          <FormControlLabel
            label="Private"
            labelPlacement="start"
            control={<Checkbox checked={isChecked} onChange={handleChecked} />}
            sx={{
              display:
                props.title === "Create Folder" ||
                props.title === "Create List" ||
                props.title === "Create Note"
                  ? "none"
                  : "flex",
            }}
          />
          <Divider
            orientation={"vertical"}
            flexItem
            sx={{
              borderColor: theme.palette.primary.main,
              display:
                props.title === "Create Folder" ||
                props.title === "Create List" ||
                props.title === "Create Note"
                  ? "none"
                  : "flex",
            }}
          />

          <Button
            key={`${props.title}-dialog-actions-button`}
            onClick={() => {
              setValue("");
              props.yesAction(value, isChecked ? false : true);
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
