import { OktaAuthOptions } from "@okta/okta-auth-js";

export const isProduction = import.meta.env.PROD;

export const redirectUri = isProduction
  ? "https://" + window.location.hostname + "/login/callback"
  : "http://localhost:3000/login/callback";

export const oktaConfig: OktaAuthOptions = {
  issuer: import.meta.env.VITE_OKTA_ISSUER as string,
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  redirectUri: redirectUri,
  scopes: ["openid", "profile", "email", "offline_access"],
};
