import {
  ArcElement,
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Colors,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";

import { Box } from "@mui/system";
import { PolarArea } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Colors,
  Filler,
  Tooltip,
  Legend
);

export type PlayerMetricsPolarChartProps = {
  chartOptions: ChartOptions<"polarArea">;
  datasets: ChartDataset<"polarArea", number[]>[];
  statLabels: string[];
};

const PlayerMetricsPolarChart = (props: PlayerMetricsPolarChartProps) => {
  const data: ChartData<"polarArea", number[], unknown> = {
    labels: props.statLabels,
    datasets: props.datasets,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 10,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "inherit",
      }}
    >
      <PolarArea data={data} options={props.chartOptions} />
    </Box>
  );
};

export default PlayerMetricsPolarChart;
