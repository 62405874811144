import type {} from "@mui/x-data-grid-premium/themeAugmentation";

import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

import Common from "./common";

export const cffcTheme: Theme = responsiveFontSizes(
  createTheme({
    ...Common,
    palette: {
      common: {
        black: "#000000",
        white: "#ffffff",
      },
      background: {
        paper: "#ffffff",
        default: "#ffffff",
      },
      primary: {
        // Dark Blue
        light: "#413f72",
        main: "#141946",
        dark: "#00001e",
        contrastText: "#ffffff",
      },
      secondary: {
        // Red
        light: "#FFE3E3",
        main: "#ff0000",
        dark: "#c20000",
        contrastText: "#ffffff",
      },
      info: {
        // Light Blue
        light: "#b0ffff",
        main: "#7ccdef",
        dark: "#479cbc",
        contrastText: "#000000",
      },
      error: {
        // Red
        light: "#ff5a36",
        main: "#d90000",
        dark: "#c20000",
        contrastText: "#ffffff",
      },
      warning: {
        // Yellow
        light: "#fff04e",
        main: "#F6BE00",
        dark: "#be8e00",
        contrastText: "#000000",
      },
      success: {
        // Green
        light: "#b0ffff",
        main: "#008000",
        dark: "#479cbc",
        contrastText: "#000000",
      },
    },
  })
);
