import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { GET_S3_PLAYER_FILES, UploadedFile, uploadFile } from "../utils/s3";

import CloudDoneIcon from "@mui/icons-material/CloudDone";
import ErrorIcon from "@mui/icons-material/Error";
import Files from "react-files";
import { useInvalidate } from "../api/keys";
import { useState } from "react";
import { useThemeContext } from "../contexts/CustomThemeContext";

const FileDropzone = (props: { playerId: number }) => {
  const { theme } = useThemeContext();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const invalidate = useInvalidate();

  const handleFileChange = async (files: any) => {
    for (const file of files) {
      const response = await uploadFile(file, `${props?.playerId}`);
      const newUploadedFiles = uploadedFiles;
      newUploadedFiles?.push(response);
      // console.debug(newUploadedFiles);
      setUploadedFiles(newUploadedFiles);
    }
    invalidate(GET_S3_PLAYER_FILES);
  };

  const handleError = (error: Error) => {
    console.error(error);
  };

  return (
    <div className="files">
      <Files
        className="files-dropzone"
        onChange={handleFileChange}
        onError={handleError}
        accepts={[".pdf", "image/*", "audio/*", "video/*"]}
        multiple
        maxFileSize={10000000}
        minFileSize={0}
        clickable
      >
        <Box
          border={1}
          borderRadius={1}
          borderColor={theme.palette.primary.main}
          padding={4}
          sx={{
            cursor: "copy",
            borderStyle: "dashed",
          }}
        >
          <Typography variant="body2" color="primary">
            Drop files here or click to upload
          </Typography>
        </Box>

        <List dense>
          {uploadedFiles?.map((file: UploadedFile) => {
            return (
              <ListItem
                key={file.name}
                disableGutters
                secondaryAction={
                  file.success ? (
                    <CloudDoneIcon htmlColor="green" fontSize="small" />
                  ) : (
                    <ErrorIcon htmlColor="red" fontSize="small" />
                  )
                }
              >
                <ListItemText>
                  <Typography variant="caption">{file.name}</Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Files>
    </div>
  );
};

export default FileDropzone;
