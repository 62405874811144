import { SCOUTING_ROLE, SUPERUSER } from "../../constants";
import { Share, User } from "../../api/types";
import { useShareMutation, useUnshareMutation } from "../../api/mutations";

import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MouseEvent } from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useConstantsContext } from "../../contexts/ConstantsContext";
import { useOktaAuthContext } from "../../contexts/OktaAuthContext";
import { useShares } from "../../api/queries";

interface ShareMenuProps {
  anchorEl: null | HTMLElement;
  menuId: string;
  objectId: number;
  objectType: string;
  open: boolean;
  onClose: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ShareMenu(props: ShareMenuProps) {
  // Context
  const { email } = useOktaAuthContext();
  const { users } = useConstantsContext();

  // QUERIES
  const sharesQueryResult = useShares(
    props.open,
    props.objectId,
    props.objectType
  );

  // MUTATIONS
  const shareMutation = useShareMutation();
  const unShareMutation = useUnshareMutation();

  return (
    <Menu
      key={`${props.objectType}-${props.objectId}-paper-menu`}
      id={props.menuId}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      open={props.open}
      onClose={props.onClose}
      MenuListProps={{
        "aria-labelledby": "share-button",
      }}
    >
      {users
        ?.sort((a: User, b: User) => a.name.localeCompare(b.name))
        ?.map((user: User) => {
          // Skip ourselves (we can't share with ourselves) except in development
          if (user.email === email && import.meta.env.PROD) {
            return null;
          }

          // Always skip these
          if (
            user.email === "joe.mansueto@morningstar.com" ||
            user.email === "footballanalytics@chicagofirefc.com" ||
            user.email === "firevisas@chicagofirefc.com" ||
            !(
              user.roles.includes(SUPERUSER) ||
              user.roles.includes(SCOUTING_ROLE)
            )
          ) {
            return null;
          }

          let sharedObj: Share | undefined;

          if (sharesQueryResult && sharesQueryResult.data) {
            sharedObj = sharesQueryResult.data?.find(
              (share: Share) =>
                share.share_with_email === user.email &&
                share.object_id === props.objectId &&
                share.object_type === props.objectType
            );
          }

          const onClick = (
            event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
          ) => {
            if (sharedObj) {
              unShareMutation.mutate(sharedObj);
            } else {
              shareMutation.mutate({
                object_id: props.objectId,
                object_type: props.objectType,
                sharer_email: email,
                share_with_email: user.email,
              } as Share);
            }
            event.stopPropagation();
          };

          return (
            <MenuItem
              key={`${user.email}-${props.objectType}-${props.objectId}-paper-menuitem`}
              onClick={(event) => onClick(event)}
            >
              {sharedObj ? (
                <CheckIcon
                  key={`${props.objectType}-${props.objectId}-${user.email}-check-icon`}
                  fontSize="small"
                  sx={{ color: "green" }}
                />
              ) : (
                <NotInterestedIcon
                  key={`${props.objectType}-${props.objectId}-${user.email}-not-interested-icon`}
                  fontSize="small"
                  sx={{ color: "red" }}
                />
              )}

              <ListItemText
                key={`${props.objectType}-${props.objectId}-paper-menu-item-list-item-text`}
                slotProps={{
                  primary: { noWrap: true, variant: "caption" },
                }}
                sx={{
                  paddingLeft: 1,
                }}
              >
                {user.name}
              </ListItemText>
            </MenuItem>
          );
        })}
    </Menu>
  );
}
