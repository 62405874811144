import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Note, Player, PlayerList } from "../../api/types";
import {
  SEARCH_PARAMS_LIST_ID,
  SEARCH_PARAMS_NOTE_ID,
  SEARCH_PARAMS_PLAYER_ID,
} from "../../constants";
import { generateSignedUrl, useFetchFiles } from "../../utils/s3";

import { ObjectList } from "aws-sdk/clients/s3";
import { SCOUTING_DEPTH_ROUTE } from "../../routes";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import { usePlayerNotes } from "../../api/queries";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

interface PlayerProfileSideBarProps {
  player: Player;
}

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
const typographyVariant = "david";
const cursorStyle = "pointer";
const textoverFlowStyle = "ellipsis";

interface S3Object {
  Key: string;
  publicUrl: string;
}

export const PlayerProfileSideBar = (props: PlayerProfileSideBarProps) => {
  const [fileList, setFileList] = useState<ObjectList>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useFetchFiles(`${props.player.id}`, setFileList);

  // CONTEXTS
  const { theme } = useThemeContext();
  const { height } = useWindowSizeContext();

  const playerNotesQuery = usePlayerNotes(props.player.id);
  const notes = playerNotesQuery.data;

  // FUNCTIONS
  const onNoteLinkClick = (note: Note) => {
    searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(note.player_id));
    searchParams.set(SEARCH_PARAMS_NOTE_ID, String(note.id));
    setSearchParams(searchParams);
  };

  const onListLinkClick = (list: PlayerList) => {
    searchParams.delete(SEARCH_PARAMS_PLAYER_ID);
    searchParams.set(SEARCH_PARAMS_LIST_ID, String(list.id));
    setSearchParams(searchParams);
  };

  const maxHeight = height / 4;

  return (
    <Stack
      spacing={4}
      sx={{
        color: `${theme.palette.primary.main} !important`,
        height: "100%",
      }}
    >
      <Box maxHeight={maxHeight}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          NOTES
        </Typography>
        <Stack spacing={1}>
          {notes &&
            notes.map((n) => {
              const noteCreatedAt = timeAgo.format(
                moment.utc(n.updated_at).toDate()
              );
              return (
                <Box key={n.id} display={"flex"} flexDirection={"column"}>
                  <Typography
                    key={n.id}
                    variant={typographyVariant}
                    noWrap
                    textOverflow={textoverFlowStyle}
                  >
                    {`• `}
                    <Tooltip title={n.content} placement="left">
                      <Link
                        onClick={() => onNoteLinkClick(n)}
                        sx={{ cursor: cursorStyle }}
                      >
                        {n.content}
                      </Link>
                    </Tooltip>
                  </Typography>
                  <Box display={"flex"} justifyContent={"space-between"} pl={1}>
                    <Tooltip title={n.created_by} placement="left">
                      <Typography
                        variant={typographyVariant}
                        fontStyle={"italic"}
                        noWrap
                        textOverflow={textoverFlowStyle}
                      >
                        {n.created_by}
                      </Typography>
                    </Tooltip>
                    <Tooltip title={noteCreatedAt} placement="left">
                      <Typography
                        variant={typographyVariant}
                        noWrap
                        textOverflow={textoverFlowStyle}
                      >
                        {noteCreatedAt}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              );
            })}
        </Stack>
      </Box>

      <Box maxHeight={maxHeight}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          LISTS
        </Typography>
        <Stack>
          {props.player.lists &&
            props.player.lists.map((list: PlayerList) => {
              return (
                <Typography
                  key={list.id}
                  variant={typographyVariant}
                  noWrap
                  textOverflow={textoverFlowStyle}
                >
                  {`• `}
                  <Tooltip title={list.name} placement="left">
                    <Link
                      onClick={() => onListLinkClick(list)}
                      sx={{ cursor: cursorStyle }}
                    >
                      {list.name}
                    </Link>
                  </Tooltip>
                </Typography>
              );
            })}
        </Stack>
      </Box>

      <Box maxHeight={maxHeight}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          DEPTH CHARTS
        </Typography>
        <Stack spacing={1}>
          {props.player.depth_charts?.map((dc) => {
            return (
              <Typography key={dc.label} variant={typographyVariant}>
                {`• `}
                <Tooltip title={dc.label} placement="left">
                  <Link
                    href={`${SCOUTING_DEPTH_ROUTE}?depth_chart_id=${dc.id}`}
                    key={dc.id}
                    sx={{ cursor: cursorStyle }}
                  >
                    {dc.label}
                  </Link>
                </Tooltip>
              </Typography>
            );
          })}
        </Stack>
      </Box>

      <Box maxHeight={maxHeight}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          FILES
        </Typography>
        <Stack>
          {fileList?.map((file) => {
            // console.debug(file);
            const f = file as S3Object;
            const fileName = f.Key?.split("/")[1];
            return (
              <Typography
                key={f.Key}
                variant={typographyVariant}
                noWrap
                textOverflow="ellipses"
              >
                {`• `}
                <Tooltip title={fileName} placement="left">
                  <Link
                    href={generateSignedUrl(f.Key)}
                    key={f?.publicUrl || ""}
                    sx={{ cursor: cursorStyle }}
                  >
                    {fileName}
                  </Link>
                </Tooltip>
              </Typography>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
