import "./index.css";

import App from "./App";
import CacheBuster from "react-cache-buster";
import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import en from "javascript-time-ago/locale/en";
import packageJson from "../package.json";
import reportWebVitals from "./reportWebVitals";

console.debug(`Version: ${packageJson.version}`);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUIX_LICENSE_KEY as string);
TimeAgo.addDefaultLocale(en);

const isProduction = import.meta.env.PROD;
if (isProduction) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID || "",
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || "",
    service: "pond-webapp",
    env: import.meta.env.PROD ? "production" : "development",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "us5.datadoghq.com",
    version: packageJson.version,
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: "mask-user-input",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    trackLongTasks: true,
    enablePrivacyForActionName: true,
  });
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true}
      isVerboseMode={true}
      metaFileDirectory={`/meta.json?t=${Date.now()}#`}
    >
      <Router>
        <App />
      </Router>
    </CacheBuster>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
