import {
  DialogContent,
  DialogContentText,
  Divider,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Folder, Player, PlayerList } from "../../api/types";
import {
  LIST_ACTION_PLAYER_ADD,
  useUpdateListPlayersMutation,
} from "../../api/mutations";

import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useLists } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface ListSelectionDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  players: Player[];
}

export function ListSelectionDialog(props: ListSelectionDialogProps) {
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [search, setSearch] = useState<string | undefined>();
  const folders = useLists();
  const [allPlayerLists, setAllPlayerLists] = useState<PlayerList[]>();

  const addPlayersToList = useUpdateListPlayersMutation(LIST_ACTION_PLAYER_ADD);

  useEffect(() => {
    let allPlayerLists: PlayerList[] = [];

    if (folders.data && folders.isFetched) {
      // eslint-disable-next-line array-callback-return
      folders.data.map((folder: Folder) => {
        allPlayerLists = allPlayerLists.concat(folder.lists);
      });

      setAllPlayerLists(allPlayerLists);
    }
  }, [folders.data, folders.isFetched]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleListItemClick = (players: Player[], playerList: PlayerList) => {
    playerList.players.data = players;
    addPlayersToList.mutate(playerList);
    props.setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullScreen={isScreenSmall}
      PaperProps={{
        width: isScreenSmall ? "100vw" : "60vw",
        height: "fit-content",
      }}
    >
      <CloseButton
        top={10}
        position="absolute"
        onClick={() => props.setOpen(false)}
      />

      <DialogTitle justifyContent="center" alignItems="center" display="flex">
        Select List
      </DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-search"
          fullWidth
          label="Search"
          type="search"
          onChange={(event) => setSearch(event.target.value)}
          sx={{ marginTop: 1, width: "100%" }}
        />
        <List disablePadding>
          <Divider sx={{ marginTop: 2 }} />
          {allPlayerLists &&
            allPlayerLists
              .filter((list) =>
                search
                  ? list.name.toLowerCase().includes(search.toLowerCase())
                  : true
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((playerList: PlayerList) => {
                return (
                  <ListItem disableGutters disablePadding key={playerList.name}>
                    <ListItemButton
                      onClick={() =>
                        handleListItemClick(props.players, playerList)
                      }
                    >
                      <ListItemText
                        primary={playerList.name}
                        slotProps={{
                          primary: { noWrap: true },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
