import { PlayerProfile, Position } from "./api/types";

export interface StatInfo {
  [name: string]: {
    label: string;
    elongated: string;
    explanation: string;
  };
}

export interface Stats {
  [name: string]: string;
}

export interface PerformanceMetrics {
  [name: string]: string;
}

export const DBT_ANALYTICS_JOB_ID = 191502;
export const DBT_STS_JOB_ID = 548236;
export const DBT_STATSBOMB_JOB_ID = 191499;
export const DBT_TABLEAU_JOB_ID = 498986;
export const DBT_TM_JOB_ID = 550621;
export const DBT_ANALYTICS_EXPOSURE_URL = `https://metadata.cloud.getdbt.com/exposure-tile?name=analytics&jobId=${DBT_ANALYTICS_JOB_ID}&token=${
  import.meta.env.VITE_DBT_METADATA_API_TOKEN
}`;
export const DBT_STATSBOMB_EXPOSURE_URL = `https://metadata.cloud.getdbt.com/exposure-tile?name=statsbomb&jobId=${DBT_STATSBOMB_JOB_ID}&token=${
  import.meta.env.VITE_DBT_METADATA_API_TOKEN
}`;
export const DBT_STS_EXPOSURE_URL = `https://metadata.cloud.getdbt.com/exposure-tile?name=sts&jobId=${DBT_STS_JOB_ID}&token=${
  import.meta.env.VITE_DBT_METADATA_API_TOKEN
}`;
export const DBT_TABLEAU_EXPOSURE_URL = `https://metadata.cloud.getdbt.com/exposure-tile?name=tableau&jobId=${DBT_TABLEAU_JOB_ID}&token=${
  import.meta.env.VITE_DBT_METADATA_API_TOKEN
}`;
export const DBT_TM_EXPOSURE_URL = `https://metadata.cloud.getdbt.com/exposure-tile?name=transfermarkt&jobId=${DBT_TM_JOB_ID}&token=${
  import.meta.env.VITE_DBT_METADATA_API_TOKEN
}`;

export const PFF_STAT_INFO: StatInfo = {
  pff_max_speed: {
    label: "Max Speed",
    elongated: "Max Speed",
    explanation:
      "Max Speed. Fastest speed of a player (km/h). Average of each player’s Max Speed from their top 5 fastest games",
  },
  pff_avg_speed: {
    label: "Avg Speed",
    elongated: "Average Speed",
    explanation: "Average Speed. Average speed of a player (km/h)",
  },
  pff_spt_pct: {
    label: "Spt%",
    elongated: "Sprint %",
    explanation:
      "Sprinting Percentage. Sprinting distance as a percentage of total distance",
  },
  pff_hsr_pct: {
    label: "HighSpdRun%",
    elongated: "High Speed Run %",
    explanation:
      "High Speed Running Percentage. High Speed Running distance as a percentage of total distance",
  },
  pff_tot_p90: {
    label: "TotDist",
    elongated: "Total Dist",
    explanation: "Total Distance. Total distance covered in km",
  },
  pff_walk_p90: {
    label: "WalkDist (km)",
    elongated: "Walk Dist",
    explanation:
      "Walking Distance. Total distance covered (km) when a player is moving < 7 km/h",
  },
  pff_jog_p90: {
    label: "JogDist (km)",
    elongated: "Jog Dist ",
    explanation:
      "Jogging Distance. Total distance covered (km) when a player is moving 7-15 km/h",
  },
  pff_lsr_p90: {
    label: "LowSpdRunDist",
    elongated: "Low Speed Run Dist",
    explanation:
      "Low Speed Running Distance. Total distance covered (km) when a player is moving 15-20km/h",
  },
  pff_hsr_p90: {
    label: "HighSpdRunDist",
    elongated: "High Speed Run Dist",
    explanation:
      "High Speed Running Distance. Total distance covered (km) when a player is moving 20-25km/h",
  },
  pff_spr_p90: {
    label: "SprtDist",
    elongated: "Sprint Dist",
    explanation:
      "Sprint Distance. Total distance covered (km) when a player is moving >=25 km/h",
  },
  pff_walkt_p90: {
    label: "WalkTime",
    elongated: "Walk Time",
    explanation:
      "Walking Time. Time on pitch when a player is moving at speed <7 km/h",
  },
  pff_jogt_p90: {
    label: "JogTime",
    elongated: "Jog Time",
    explanation:
      "Jogging Time. Time on pitch when a player is moving at speed 7-15 km/h",
  },
  pff_lsrt_p90: {
    label: "LowSpdRunTime",
    elongated: "Low Speed Run Time",
    explanation:
      "Low Speed Running Time. Time on pitch when a player is moving at speed 15-20 km/h",
  },
  pff_hsrt_p90: {
    label: "HighSpdRunTime",
    elongated: "High Speed Run Time",
    explanation:
      "High Speed Running Time. Time on pitch when a player is moving at speed 20-25 km/h",
  },
  pff_sprt_p90: {
    label: "SpintTime",
    elongated: "Sprint Time",
    explanation:
      "Sprinting Time. Time on pitch when a player is moving at speed >=25 km/h",
  },
  pff_hsrc_p90: {
    label: "HighSpdRunCount",
    elongated: "High Spd Run Count",
    explanation:
      "High Speed Running Counts. A count of how many times a player moved 20-25 km/h",
  },
  pff_sprc_p90: {
    label: "SprintCount",
    elongated: "Sprint Count",
    explanation:
      "Sprint Counts. A count of how many times a player moved >=25 km/h",
  },
  pff_accel_p90: {
    label: "AccelCount",
    elongated: "Accel Count",
    explanation:
      "Acceleration Counts. A count of how many times a player accelerated over 3 m/s2",
  },
  pff_decel_p90: {
    label: "DecelCount",
    elongated: "Decel Count",
    explanation:
      "Deceleration Counts. A count of how many times a player decelerated over 3 m/s2",
  },
};

export const THREE_SIXTY_STAT_INFO: StatInfo = {
  f3_lbp_completed_90: {
    label: "F3 LBP Completed p90",
    elongated: "F3 LBP completed p90",
    explanation:
      "The number of Line Breaking Passes completed in the final third per 90",
  },
  f3_lbp_received_90: {
    label: "F3 LBP Received p90",
    elongated: "F3 LBP received p90",
    explanation:
      "The number of Line Breaking Passes received in the final third per 90",
  },
  lbp_completed_90: {
    label: "LBP Completed p90",
    elongated: "LBP (LBP) Completed per 90",
    explanation:
      "The number of line breaking passes completed per ninety minutes",
  },
  lbp_received_90: {
    label: "LBP Received p90",
    elongated: "LBP received per 90",
    explanation: "The number of Line Breaking Passes received per 90",
  },
  lbp_to_space_2_90: {
    label: "LBP Played 2m p90",
    elongated: "LBP played within 2m of space of the receiver",
    explanation:
      "The number of Line Breaking Passes played into at least 2m of space of within receiver per 90",
  },
  lbp_to_space_5_90: {
    label: "LBP Played 5m p90",
    elongated: "LBP played within 5m of space of the receiver",
    explanation:
      "The number of Line Breaking Passes played into at least 5m of space of within receiver per 90",
  },
  lbp_to_space_2_received_90: {
    label: "LBP Received 2m p90",
    elongated: "LBP received within 2m of space",
    explanation:
      "The number of Line Breaking Passes received within at least 2m of space of the receiver per 90",
  },
  lbp_to_space_5_received_90: {
    label: "LBP Received 5m p90",
    elongated: "LBP received within 5m of space",
    explanation:
      "The number of Line Breaking Passes received within at least 5m of space of the receiver per 90",
  },
  obv_lbp_90: {
    label: "OBVLBP90",
    elongated: "OBV LBP p90",
    explanation: "On Ball Value Added (net) from Line Breaking Passes",
  },
};

export const NON_THREE_SIXTY_STAT_INFO: StatInfo = {
  // Match Player Stats

  player_name: {
    label: "Player",
    elongated: "Player Name",
    explanation: "Player's full name.",
  },
  player_match_aerial_ratio: {
    label: "Aer%",
    elongated: "Aerial Ratio",
    explanation: "Percentage of aerial duels a player enters that they win.",
  },
  player_match_aerials: {
    label: "Aer",
    elongated: "Aerials",
    explanation: "Number of aerial duels.",
  },
  player_match_aggressive_actions: {
    label: "AA",
    elongated: "Aggressive Actions",
    explanation:
      "Tackles, pressure events and fouls recorded within 2 seconds of an opposition ball receipt.",
  },
  player_match_assists: {
    label: "A",
    elongated: "Assists",
    explanation: "Number of assists.",
  },
  player_match_op_assists: {
    label: "A",
    elongated: "Open Play Assists",
    explanation: "Number of open play assists.",
  },
  player_match_backward_passes: {
    label: "BP",
    elongated: "Backward Passes",
    explanation: "Number of backward passes.",
  },
  player_match_ball_recoveries: {
    label: "BR",
    elongated: "Ball Recoveries",
    explanation: "Number of ball recoveries.",
  },
  player_match_box_cross_ratio: {
    label: "BCR",
    elongated: "Box Cross Ratio",
    explanation:
      "What percentage of a player's completed passes into the box are crosses. See Event Specification for details of cross location definition.",
  },
  player_match_challenge_ratio: {
    label: "CR",
    elongated: "Challenge Ratio",
    explanation:
      "Percentage of time a player makes a tackle when going into a duel vs getting dribbled past.",
  },
  player_match_clearances: {
    label: "MR",
    elongated: "Match Clearances",
    explanation: "Number of clearances or long balls attempted.",
  },
  player_match_counterpressure_duration_avg: {
    label: "CPDA",
    elongated: "Counter Pressure Duration Average",
    explanation: "Average duration of counterpressures.",
  },
  player_match_counterpressure_duration_total: {
    label: "CPDT",
    elongated: "Counter Pressure Duration Total",
    explanation: "Total duration of counterpressures.",
  },
  player_match_counterpressured_action_fails: {
    label: "CPAF",
    elongated: "Counter Pressure Action Fails",
    explanation: "Number of counterpressures attempted that failed.",
  },
  player_match_counterpressures: {
    label: "CP",
    elongated: "Counter Pressures",
    explanation:
      "Number of counterpressures. Counterpressures are pressures exerted within 5 seconds of a turnover.",
  },
  player_match_crosses: {
    label: "C",
    elongated: "Crosses",
    explanation: "Completed crosses.",
  },
  player_match_crosses_into_box: {
    label: "NCB",
    elongated: "Number of crosses into the box",
    explanation: "Number of crosses into the box.",
  },
  player_match_crossing_ratio: {
    label: "Cx%",
    elongated: "Crossing Ratio",
    explanation:
      "Percentage of attempted crosses that are successful and received by a teammate.",
  },
  player_match_deep_completions: {
    label: "DC",
    elongated: "Deep Completions",
    explanation: "Number of deep completions.",
  },
  player_match_deep_progressions: {
    label: "F3rdE",
    elongated: "F3 Progressions",
    explanation: "Passes and dribbles/carries into the opposition final third.",
  },
  player_match_dispossessions: {
    label: "MD",
    elongated: "Dispossessions",
    explanation: "Number of times a player loses the ball by getting tackled.",
  },
  player_match_dribbled_past: {
    label: "DP",
    elongated: "Dribbled Passed",
    explanation: "How often a player fails a challenge and is dribbled past.",
  },
  player_match_dribbles: {
    label: "Drbls",
    elongated: "Dribbles",
    explanation: "How often a player successfully dribbles past an opponent.",
  },
  player_match_dribbles_faced: {
    label: "Drbls Fcd",
    elongated: "Dribbles Faced",
    explanation: "How often a player faces a dribble.",
  },
  player_match_op_f3_backward_passes: {
    label: "Z3BackPass",
    elongated: "F3 Backward Passes",
    explanation: "Number of open play, final third backward passes.",
  },
  player_match_op_f3_forward_passes: {
    label: "Z3FwdPass",
    elongated: "F3 Forward Passes",
    explanation: "Number of open play, final third forward passes.",
  },
  player_match_op_f3_passes: {
    label: "Z3Pass",
    elongated: "OP F3 Passes",
    explanation: "Number of open play, final third passes.",
  },
  player_match_op_f3_sideways_passes: {
    label: "Z3SidePass",
    elongated: "OP F3 Sidways Passes",
    explanation: "Number of open play, final third sideways passes.",
  },
  player_match_fhalf_ball_recoveries: {
    label: "Z1BallRecov",
    elongated: "Final Half Ball Recoveries",
    explanation:
      "How many ball recoveries the player made in the opposition (final) half of the pitch.",
  },
  player_match_forward_passes: {
    label: "FwdPass",
    elongated: "Forward Passes",
    explanation: "How many forward passes a player makes.",
  },
  player_match_fouls: {
    label: "FC",
    elongated: "Fouls Committed",
    explanation: "How many fouls a player commits.",
  },
  player_match_fouls_won: {
    label: "FW",
    elongated: "Fouls Won",
    explanation: "How many times a player is fouled.",
  },
  player_match_goals: {
    label: "MG",
    elongated: "Match Goals",
    explanation: "All goals scored by the player, including penalties.",
  },
  player_match_np_goals: {
    label: "NPG",
    elongated: "Non Penalty Goals",
    explanation: "All goals scored by the player, excluding penalties.",
  },
  player_match_interceptions: {
    label: "Itxs",
    elongated: "Interceptions",
    explanation: "Number of interceptions.",
  },
  player_match_key_passes: {
    label: "KeyPass",
    elongated: "Key Passes",
    explanation:
      'Passes that create shots for teammates. Can also be seen to be referred to as "shot assists" or "chances created".',
  },
  player_match_op_key_passes: {
    label: "KeyPass",
    elongated: "Key Passes",
    explanation:
      'Open play passes that create shots for teammates per nintey minutes played. Can also be seen to be referred to as "shot assists" or "chances created".',
  },
  player_match_long_ball_ratio: {
    label: "LongBall%",
    elongated: "Long Ball Ratio",
    explanation:
      "Percentage of attempted long balls that are actually completed.",
  },
  player_match_long_balls: {
    label: "LongBalls",
    elongated: "Long Balls",
    explanation: "Number of completed long balls.",
  },
  player_match_minutes: {
    label: "MP",
    elongated: "Minutes",
    explanation: "The number of minutes the player has played.",
  },
  player_match_obv: {
    label: "OBV",
    elongated: "On Ball Value",
    explanation: "On Ball Value Added (net) total (all event types)",
  },
  player_match_obv_pass: {
    label: "OBVPas",
    elongated: "On Ball Value Pass",
    explanation: "On Ball Value added (net) from Passes.",
  },
  player_match_obv_shot: {
    label: "OBVSh",
    elongated: "On Ball Value Shot",
    explanation: "On Ball Value added (net) from Shots.",
  },
  player_match_obv_defensive_action: {
    label: "OBVDA",
    elongated: "On Ball Value Defensive Action",
    explanation: "On Ball Value Added (net) from Defensive Actions.",
  },
  player_match_obv_gk: {
    label: "OBVGK",
    elongated: "On Ball Value Goalkeeper",
    explanation: "On Ball Value Added (net) Goalkeeper.",
  },
  player_match_obv_dribble_carry: {
    label: "OBVdDr",
    elongated: "On Ball Value Dribble",
    explanation: "On Ball Value Added (net) from Dribbles and Carries.",
  },
  player_match_passes: {
    label: "AP",
    elongated: "Attempted Passes",
    explanation: "Number of attempted passes.",
  },
  player_match_op_passes: {
    label: "P",
    elongated: "OP Attempted Passes",
    explanation: "Number of attempted passes in open play.",
  },
  player_match_passes_into_box: {
    label: "PIB",
    elongated: "Passes Into Box",
    explanation: "Successful passes into the box from outside the box.",
  },
  player_match_op_passes_into_box: {
    label: "PiB",
    elongated: "OP Passes into Box",
    explanation:
      "Successful passes into the box from outside the box (open play).",
  },
  player_match_passing_ratio: {
    label: "Pass%",
    elongated: "Passing Ratio",
    explanation: "Percentage of all passes attempted that were completed.",
  },
  player_match_penalties_faced: {
    label: "PF",
    elongated: "Penalties Faced",
    explanation: "Number of penalties faced.",
  },
  player_match_penalties_conceded: {
    label: "PC",
    elongated: "Penalties Conceded",
    explanation: "Number of penalties faced that resulted in goals.",
  },
  player_match_penalties_won: {
    label: "PW",
    elongated: "Penalties Won",
    explanation: "Number of penalties won.",
  },
  player_match_possession: {
    label: "Poss",
    elongated: "Possession",
    explanation: "How much possession a player has per match.",
  },
  player_match_pressure_duration_avg: {
    label: "PrsDurAvg",
    elongated: "Pressure Duration Average",
    explanation:
      "Standard metric for how much possession a player has in total per match.",
  },
  player_match_pressure_duration_total: {
    label: "PrsDurTot",
    elongated: "Pressure Duration Total",
    explanation:
      "Standard metric for how much possession a player averages per match.",
  },
  player_match_pressure_regains: {
    label: "PrsRgns",
    elongated: "Pressure Duration Regains",
    explanation:
      "Times a player won the ball back within 5 seconds of the player pressuring an opponent.",
  },
  player_match_pressured_action_fails: {
    label: "PrsFails",
    elongated: "Pressure Action Fails",
    explanation:
      "Times a player failed to win the ball back within 5 seconds of the player pressuring an opponent.",
  },
  player_match_pressured_long_balls: {
    label: "PrsLongBalls",
    elongated: "Pressured Long Balls",
    explanation:
      "Number of clearances or long balls attempted while under pressure.",
  },
  player_match_pressures: {
    label: "Pressures",
    elongated: "Pressures",
    explanation: "The number of times a player pressures an opposition player.",
  },
  player_match_np_psxg: {
    label: "PSxG",
    elongated: "NP PS xG",
    explanation: "Post Shot xG earned from on-target shots.",
  },
  player_match_shot_touch_ratio: {
    label: "STR",
    elongated: "Shot Touch Ratio",
    explanation:
      "The amount of shots a player takes as a proportion of their touches of the ball.",
  },
  player_match_np_shots: {
    label: "npSh",
    elongated: "NP Shots",
    explanation: "Number of shots not from penalties.",
  },
  player_match_op_shots: {
    label: "Sh",
    elongated: "OP Shots",
    explanation: "Number of shots from open play.",
  },
  player_match_shots_blocked: {
    label: "ShB",
    elongated: "Shots Blocked",
    explanation: "Number of shots blocked.",
  },
  player_match_np_shots_on_target: {
    label: "ShoT",
    elongated: "NP Shots on Target",
    explanation: "Number of non-penalty shots on target.",
  },
  player_match_sideways_passes: {
    label: "SP",
    elongated: "Sideways Passes",
    explanation: "Number of sideways passes",
  },
  player_match_sp_xa: {
    label: "SP xA",
    elongated: "Set Piece xA",
    explanation: "xG assisted from set pieces.",
  },
  player_match_successful_aerials: {
    label: "SA",
    elongated: "Successful Aerials",
    explanation: "Number of successful aerials.",
  },
  player_match_successful_crosses: {
    label: "SC",
    elongated: "Successful Crosses",
    explanation: "Number of successful crosses.",
  },
  player_match_successful_long_balls: {
    label: "SLB",
    elongated: "Successful Long Balls",
    explanation: "Number of successful long balls.",
  },
  player_match_successful_passes: {
    label: "SP",
    elongated: "Successful Passes",
    explanation: "Number of successful passes.",
  },
  player_match_tackles: {
    label: "Tckls",
    elongated: "Tackles",
    explanation: "Successful challenges made.",
  },
  player_match_touches: {
    label: "Tchs",
    elongated: "Touches",
    explanation: "Number of successful footed touches.",
  },
  player_match_through_balls: {
    label: "ThruBalls",
    elongated: "Through Balls",
    explanation:
      "A completed pass splitting the defence for a teammate to run onto.",
  },
  player_match_touches_inside_box: {
    label: "TchsBx",
    elongated: "Touches in Box",
    explanation: "Successful footed touches inside the box (including shots).",
  },
  player_match_turnovers: {
    label: "Turnovers",
    elongated: "Turnovers",
    explanation:
      "How often a player loses the ball via a miscontrol or a failed dribble.",
  },
  player_match_unpressured_long_balls: {
    label: "ULB",
    elongated: "Unpressured Long Balls",
    explanation: "Times a player plays a long ball while not under pressure.",
  },
  player_match_xa: {
    label: "xA",
    elongated: "Expected Goals Assisted",
    explanation: "xG assisted.",
  },
  player_match_op_xa: {
    label: "xA",
    elongated: "Expected Assists",
    explanation: "xG assisted from open play.",
  },
  player_match_np_xg: {
    label: "xG",
    elongated: "Expected Goals",
    explanation: "xG assisted (not from penalties).",
  },
  player_match_np_xg_per_shot: {
    label: "xGpSh",
    elongated: "Expected Goals per Shot",
    explanation: "xG assisted per shot (not from penalties).",
  },
  player_match_xgbuildup: {
    label: "xgBuildup",
    elongated: "Expected Goals from Buildup",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain.",
  },
  player_match_op_xgbuildup: {
    label: "xgBuildup",
    elongated: "OP Expected Goals from Buildup",
    explanation: "xG buildup in open play.",
  },
  player_match_xgbuildup_per_possession: {
    label: "xgBuildup PP",
    elongated: "Expected Goals from Buildup per Possession",
    explanation: "xG buildup per possession.",
  },
  player_match_op_xgbuildup_per_possession: {
    label: "xgBuildup pP",
    elongated: "OP Expected Goals from Buildup per Possession",
    explanation: "xG buildup per possession (open play).",
  },
  player_match_xgchain: {
    label: "xG Chain",
    elongated: "Expected Goals Chain",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession.",
  },
  player_match_op_xgchain: {
    label: "xGChain",
    elongated: "OP Expected Goals Chain",
    explanation: "xG chain in open play.",
  },
  player_match_xgchain_per_possession: {
    label: "xG Chain PP",
    elongated: "Expected Goals Chain per Possession",
    explanation: "xG chain per possession.",
  },
  player_match_op_xgchain_per_possession: {
    label: "xGChain pP",
    elongated: "OP Expected Goals Chain per Possession",
    explanation: "xG chain per possession (open play).",
  },
  most_recent_match: {
    label: "MRM",
    elongated: "Most Recent Match",
    explanation: "The id of the most recent match",
  },
  npga_90: {
    label: "G+Ap90",
    elongated: "NP Goals and Assists",
    explanation:
      "Non-penalty goals and assists. A combined measure of the direct goal contribution of a player via goalscoring or goal assisting",
  },
  xa_90: {
    label: "xGA",
    elongated: "xG Assisted",
    explanation:
      "xG assisted. This is calculated from the expected goal value of the assisted shot",
  },
  key_passes_90: {
    label: "KPp90",
    elongated: "Key Passes p90",
    explanation:
      'Passes that create shots for teammates. Can also be seen to be referred to as "shot assists" or "chances created"',
  },
  assists_90: {
    label: "A",
    elongated: "Assists",
    explanation: "Number of assists",
  },
  op_passes_into_and_touches_inside_box_90: {
    label: "Pass+TchsBxp90",
    elongated: "Open Play Passes into Box from Outside the Box",
    explanation:
      "Successful passes into the box from outside the box (open play) and touches inside the box combined",
  },
  padj_tackles_90: {
    label: "pAdjTcklsp90",
    elongated: "Tackles pAdj to possession",
    explanation:
      "Number of tackles adjusted proportionally to the possession volume of a team",
  },
  padj_interceptions_90: {
    label: "pAdjItxsp90",
    elongated: "Number of Interceptions pAdj to possesion",
    explanation:
      "Number of interceptions adjusted proportionally to the possession volume of a team",
  },
  challenge_ratio: {
    label: "Chllng%",
    elongated: "Challenge Ratio",
    explanation:
      "Percentage of time a player makes a tackle when going into a duel vs getting dribbled past",
  },
  blocks_per_shot: {
    label: "Blocks pSh",
    elongated: "Blocks per Shot Faced",
    explanation: "Blocks made per shot faced",
  },
  clearance_90: {
    label: "Clrncs",
    elongated: "Clearances",
    explanation: "Number of clearances made by a player",
  },
  aerial_wins_90: {
    label: "AerWp90",
    elongated: "Aerial Duels Won",
    explanation: "Number of aerial duels a player wins",
  },
  forward_pass_completion: {
    label: "FwdPassCmp",
    elongated: "Forward Pass Completion Rate",
    explanation: "The rate of forward passes completed",
  },
  forward_pass_proportion: {
    label: "FwdPassPrp",
    elongated: "Forward Pass Propotion",
    explanation:
      "Proportion of a player's passes that are angled forwards. Forwards is defined as the arc of the circle from 11π/6 to π/6, of the circle 02π; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  backward_pass_proportion: {
    label: "BackPassPrp",
    elongated: "Backward Pass Propotion",
    explanation:
      "Proportion of a player's passes that are angled backwards. Backwards is defined as the arc of the circle from 5π/6 to 7π/6, of the circle 02π; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  sideways_pass_proportion: {
    label: "SidePassPrp",
    elongated: "Sideways Pass Propotion",
    explanation:
      "Proportion of a player's passes that are angled sideways. Sideways is defined as the arc of the circle from π/6 to 5π/6, and from 7π/6 to 11π/6, of the circle 02π,; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  op_f3_forward_pass_proportion: {
    label: "Z3FwdPassProp",
    elongated: "Open Play Final 3rd Forward Pass Proportion",
    explanation:
      "Proportion of a player's passes in the final third that are angled forwards. Forwards is defined as the arc of the circle from 11π/6 to π/6, of the circle 02π; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  op_f3_backward_pass_proportion: {
    label: "Z3BackPassProp",
    elongated: "Open Play Final 3rd Backward Pass Proportion",
    explanation:
      "Proportion of a player's passes in the final third that are angled backwards. Backwards is defined as the arc of the circle from 5π/6 to 7π/6, of the circle 02π; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  op_f3_sideways_pass_proportion: {
    label: "Z3SidePassProp",
    elongated: "Open Play Final 3rd Sideways Pass Proportion",
    explanation:
      "Proportion of a player's passes in the final third that are angled sideways. Sideways is defined as the arc of the circle from π/6 to 5π/6, and from 7π/6 to 11π/6, of the circle 02π,; angle-0 means a 0 change in y-coordinate (i.e. towards the opponent's goal line)",
  },
  shot_on_target_ratio: {
    label: "ShoT%",
    elongated: "Shots on Target Ratio",
    explanation:
      "The percentage of total shots by a player that are on target (includes goals, saved, and cleared off line)",
  },
  conversion_ratio: {
    label: "Conv%",
    elongated: "Conversion Ratio",
    explanation:
      "Percentage of non-penalty shots a player takes that are converted into goals",
  },
  penalty_wins_90: {
    label: "PenWon",
    elongated: "Penalties Won",
    explanation: "Number of penalties won",
  },
  padj_clearances_90: {
    label: "pAdjClrncs p90",
    elongated: "pAdj Clearances p90",
    explanation:
      "Number of clearances adjusted proportionally to the possession volume of a team",
  },
  passes_inside_box_90: {
    label: "PiBp90",
    elongated: "Passes Inside the Box p90",
    explanation: "Passes completed inside the box",
  },
  xgchain_90: {
    label: "xGCp90",
    elongated: "xg Chain p90",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  xgbuildup_90: {
    label: "xGBp90",
    elongated: "Xg Buildup p90",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain",
  },
  xgchain: {
    label: "xGC",
    elongated: "xg Chain",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  op_xgchain: {
    label: "opXgC",
    elongated: "Open Play xG Chain",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  xgbuildup: {
    label: "xGB",
    elongated: "xG Buildup",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain",
  },
  op_xgbuildup: {
    label: "OPxGB",
    elongated: "Open Play xG Buildup",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain",
  },
  xgchain_per_possession: {
    label: "xGCpP",
    elongated: "xG Chain per Possession",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  op_xgchain_per_possession: {
    label: "OPxGCpP",
    elongated: "Open Play xG Chain per Possession",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  xgbuildup_per_possession: {
    label: "xGBpP",
    elongated: "xG Buildup per Possession",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain",
  },
  op_xgbuildup_per_possession: {
    label: "OPxGBpP",
    elongated: "Open Play xG Buildup per Possession",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG",
  },
  pressured_long_balls_90: {
    label: "PrsLongBallp90",
    elongated: "Pressured Long Balls p90",
    explanation:
      "Number of clearances or long balls attempted while under pressure",
  },
  unpressured_long_balls_90: {
    label: "UnPrsLongBallp90",
    elongated: "Unpressured Long Balls p90",
    explanation: "Times a player plays a long ball while not under pressure",
  },
  aggressive_actions_90: {
    label: "AgrsAction p90",
    elongated: "Aggressive Actions p90",
    explanation:
      "Tackles, pressure events and fouls recorded within 2 seconds of an opposition ball receipt",
  },
  pressure_regains_90: {
    label: "PrsRgns p90",
    elongated: "Pressure Regains p90",
    explanation:
      "Times a player’s team won the ball back within 5 seconds of the player pressuring an opponent",
  },
  box_cross_ratio: {
    label: "BxCx%",
    elongated: "Box Cross Ratio",
    explanation:
      "What percentage of a player's completed passes into the box are crosses. See Event Specification for details of cross location definition",
  },
  sp_xa_90: {
    label: "SPxGAp90",
    elongated: "xGA from Set Pieces p90",
    explanation: "xG assisted from set pieces",
  },
  shots_faced_90: {
    label: "ShFp90",
    elongated: "Shots Faced p90",
    explanation:
      "Count of all shots faced by the goalkeeper (including off-target shots.)",
  },
  goals_faced_90: {
    label: "GFp90",
    elongated: "Goals Faced p90",
    explanation: "Count of all goals conceded by the goalkeeper",
  },
  np_xg_faced_90: {
    label: "xGFp90",
    elongated: "NP xG Faces p90",
    explanation:
      "Total xG from all non-penalty shots faced (including off target shots)",
  },
  np_psxg_faced_90: {
    label: "xGFp90",
    elongated: "NP PS xG Faced p90",
    explanation: "The post shot expected goals faced",
  },
  save_ratio: {
    label: "Save%",
    elongated: "Save Ratio",
    explanation:
      "Percentage of on-target shots that were saved by the goalkeeper",
  },
  xs_ratio: {
    label: "xS%",
    elongated: "Expected PS xG Save Ratio",
    explanation:
      "Given the post-shot xG (modelled from on frame location) of shots faced by the goalkeeper what % would we expect them to save?",
  },
  pass_length: {
    label: "AvgPassLngth",
    elongated: "Average pass length",
    explanation: "Average pass length",
  },
  s_pass_length: {
    label: "avgPassLngthComp",
    elongated: "Average pass length of completed passes",
    explanation: "Average pass length of completed passes",
  },
  p_pass_length: {
    label: "avgPassLngthPrs",
    elongated: "Average pass length of passes made under pressure",
    explanation: "Average pass length of passes made under pressure",
  },
  ps_pass_length: {
    label: "PassLngthSuccPrs",
    elongated: "Pass length of successful passes made under pressure",
    explanation: "Pass length of successful passes made under pressure",
  },
  ot_shots_faced_90: {
    label: "otShFp90",
    elongated: "On Target Shots Faces p90",
    explanation: "Count of on-target shots faced by the goalkeeper",
  },
  npot_psxg_faced_90: {
    label: "psxGFp90",
    elongated: "PS xG of on-target shots faced",
    explanation:
      "Total post-shot xG of on-target shots faced by the goalkeeper, as predicted from a trained and calibrated model of only shots on target. Penalties not included",
  },
  ot_shots_faced_ratio: {
    label: "ShF%",
    elongated: "On-Target Shots Faced Ratio",
    explanation:
      "Percentage of shots faced by the goalkeeper that were on-target",
  },
  np_optimal_gk_dlength: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pass_into_pressure_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pass_into_danger_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pressured_pass_length_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  da_aggressive_distance: {
    label: "",
    elongated: "",
    explanation: "",
  },
  positive_outcome_score: {
    label: "",
    elongated: "",
    explanation: "",
  },
  appearances: {
    label: "",
    elongated: "",
    explanation: "",
  },
  average_minutes: {
    label: "AM",
    elongated: "",
    explanation: "",
  },
  positive_outcome_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  sp_assists_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  sp_key_passes_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  over_under_performance_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  shots_key_passes_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  failed_dribbles_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  penalty_conversion_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pass_length_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pressured_change_in_pass_length: {
    label: "",
    elongated: "",
    explanation: "",
  },
  carries_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  carry_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  yellow_cards_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  defensive_action_regains_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  counterpressure_regains_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  starting_appearances: {
    label: "",
    elongated: "",
    explanation: "",
  },
  average_x_pressure: {
    label: "",
    elongated: "",
    explanation: "",
  },
  fhalf_pressures_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  fhalf_counterpressures_90: {
    label: "",
    elongated: "",
    explanation: "",
  },
  fhalf_pressures_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  fhalf_counterpressures_ratio: {
    label: "",
    elongated: "",
    explanation: "",
  },
  average_x_defensive_action: {
    label: "AvgDDA",
    elongated: "Average Distance from goal line of Defensive Action",
    explanation:
      "The average distance from the goal line that the player successfully makes a defensive action. The scale is the x-axis of the pitch, measured from 0100",
  },
  "90s_played": {
    label: "#90sP",
    elongated: "Number of 90s played",
    explanation: "Number of 90s played",
  },
  deep_completions_90: {
    label: "DCp90",
    elongated: "Number of deep completions",
    explanation: "Number of deep completions",
  },
  ball_recoveries_90: {
    label: "BallRecov p90",
    elongated: "Number of ball recoveries",
    explanation: "Number of ball recoveries",
  },
  np_psxg_90: {
    label: "npPSxGp90",
    elongated: "NP PS xG p90",
    explanation:
      "Non-Penalty Post Shot xG earned from on-target shots per ninety",
  },
  penalties_faced_90: {
    label: "PenFaced p90",
    elongated: "Number of penalties faced",
    explanation: "Number of penalties faced",
  },
  penalties_conceded_90: {
    label: "PenConced p90",
    elongated: "Penalties Conceded p90",
    explanation: "Number of penalties faced that resulted in goals",
  },
  fhalf_ball_recoveries_90: {
    label: "Z1BallRecov p90",
    elongated: "Final Half Ball Recoveries p90",
    explanation:
      "How many ball recoveries the player made in the opposition (final) half of the pitch",
  },

  // Player Season Stats
  aerial_ratio: {
    label: "Aer%",
    elongated: "Aerial Ratio",
    explanation: "Percentage of aerial duels a player enters that they win",
  },
  average_x_pass: {
    label: "AvgPD",
    elongated: "Average Pass Distance",
    explanation:
      "The average distance from the goal line that the player successfully makes a pass. The scale is the x-axis of the pitch, measured from 0100",
  },
  ball_receipts_in_space_5_ratio: {
    label: "RcptsIn5",
    elongated: "Ball Rcpts in 5m",
    explanation: "Ball Receipts in 5m of space",
  },
  carry_length: {
    label: "CarLngth",
    elongated: "Carry Length",
    explanation: "Average carry length",
  },
  cffc_obv_90: {
    label: "OBV p90",
    elongated: "On Ball Value",
    explanation: "On Ball Value Added (net) total (all event types)",
  },
  change_in_passing_ratio: {
    label: "XPass%",
    elongated: "Change in Passing Ratio",
    explanation:
      "How does passing % change when under pressure? This is calculated as Pressured Pass % minus Pass %",
  },
  clcaa: {
    label: "CLCAA",
    elongated: "Claim Collection Attempts over Average",
    explanation:
      'Claims or CCAA% Claimable Collection Attempts over Average), is a measure of how likely the goalkeeper is to attempt to claim a "claimable" pass, versus the average goalkeeper attempted claim rate.',
  },
  counterpressures_90: {
    label: "CntrPrsp90",
    elongated: "CounterPressures p90",
    explanation:
      "Number of counterpressures. Counterpressures are pressures exerted within 5 seconds of a turnover",
  },
  crosses_90: {
    label: "Cx p90",
    elongated: "Crosses p90",
    explanation: "Completed crosses",
  },
  crossing_ratio: {
    label: "Cx%",
    elongated: "Crossing Ratio",
    explanation:
      "Percentage of attempted crosses that are successful and received by a teammate",
  },
  deep_progressions_90: {
    label: "DPp90",
    elongated: "Deep Progression p90",
    explanation: "Passes and dribbles/carries into the opposition final third",
  },
  dispossessions_90: {
    label: "DisPoss p90",
    elongated: "Dispossessions p90",
    explanation: "Number of times a player loses the ball by getting tackled",
  },
  dispossessions_turnovers: {
    label: "DisPossTurn p90",
    elongated: "Dispossessions + Turnovers p90",
    explanation: "Number of dispossessions and turnovers a player makes p90",
  },
  dribbles_90: {
    label: "Drbs p90",
    elongated: "Dribbles p90",
    explanation: "How often a player successfully dribbles past an opponent",
  },
  dribble_faced_ratio: {
    label: "DrbsFaced%",
    elongated: "Dribbles Faced Ratio",
    explanation:
      "Of the dribbles faced by this player, what % of them were unsuccessful (stopped)",
  },
  dribble_ratio: {
    label: "Drb%",
    elongated: "Dribbles Ratio",
    explanation: "Percentage of dribbles that were successful",
  },
  dribbled_past_90: {
    label: "DrbdPast p90",
    elongated: "Dribbled Past p90",
    explanation: "How often a player fails a challenge and is dribbled past",
  },
  errors_90: {
    label: "Errors p90",
    elongated: "Errors p90",
    explanation:
      "How many errors the player makes per 90. An error is an on the ball mistake that led to a shot",
  },
  foul_rate: {
    label: "Fp90",
    elongated: "Foul Rate",
    explanation: "How many fouls a player commits",
  },
  fouls_90: {
    label: "Fp90",
    elongated: "Fouls p90",
    explanation: "How many fouls a player commits",
  },
  fouls_won_90: {
    label: "FWp90",
    elongated: "Fouls Won p90",
    explanation: "How many times a player is fouled",
  },
  goals_90: {
    label: "G",
    elongated: "Goals p90",
    explanation: "All goals scored by the player, including penalties",
  },
  gsaa_90: {
    label: "GSAAp90",
    elongated: "Goalie Saves Prevented",
    explanation:
      "How many goals did the keeper save/concede versus expectation (post-shot xG faced)? This is representative of how many goals the goalkeeper's saves prevented within a season",
  },
  gsaa_ratio: {
    label: "GSAA%",
    elongated: "GSAA Ratio",
    explanation:
      "The measure of goals saved above average, as a percentage of shots faced by the goalkeeper",
  },
  interceptions_90: {
    label: "Itxs p90",
    elongated: "Interceptions p90",
    explanation: "Number of interceptions",
  },
  left_foot_ratio: {
    label: "LeftFoot%",
    elongated: "Left Foot Ratio",
    explanation:
      "Proportion of footed passes made by this player with their left foot. Players can be considered left-footed when this value is over 60% (and right-footed at less than 40%",
  },
  long_ball_ratio: {
    label: "LongBall%",
    elongated: "Long Ball Ratio",
    explanation:
      "Percentage of attempted long balls that are actually completed",
  },
  long_balls_90: {
    label: "LongBalls p90",
    elongated: "Long Balls p90",
    explanation: "Number of completed long balls",
  },
  medium_pass_completion: {
    label: "Med Pass Comp. Rate",
    elongated: "Medium Pass Completion Rate",
    explanation:
      "The completion rate of passes of medium length (greater than 15m, less than 30m)",
  },
  minutes: {
    label: "SM",
    elongated: "Season Minutes",
    explanation:
      "The number of minutes played this season. This value is used to calculate all other stat values as per-90",
  },
  npg_90: {
    label: "Gp90",
    elongated: "NP Goal p90",
    explanation: "Goals scored (not including penalties)",
  },
  npxgxa_90: {
    label: "xGXAp90",
    elongated: "NP xG xA p90",
    explanation:
      "Non-penalty xG and xG Assisted. A combined measure of a player's contribution to shots via shots themselves or the key pass prior to the shot, measured in terms of expected goal values",
  },
  np_shots_90: {
    label: "Shp90",
    elongated: "NP Shots p90",
    explanation: "Number of non-penalty shots a player takes",
  },
  np_xg_per_shot: {
    label: "xGpSh",
    elongated: "NP xG per shot",
    explanation:
      "A measure of shot quality, this looks at the average non-penalty expected goal value per shot a player takes",
  },
  np_xg_90: {
    label: "xGp90",
    elongated: "NP xG p90",
    explanation: "Non-penalty expected goals produced by the player",
  },
  op_assists_90: {
    label: "Ap90",
    elongated: "OP Assists p90",
    explanation: "Number of assists from open play",
  },
  op_f3_passes_90: {
    label: "OPZ3p90",
    elongated: "OP F3 Passes p90",
    explanation: "Successful open play final third passes",
  },
  op_key_passes_90: {
    label: "KPp90",
    elongated: "OP Key Passes p90",
    explanation: "Passes that create shots for teammates, just from open play",
  },
  op_passes_90: {
    label: "Passp90",
    elongated: "OP Passes p90",
    explanation: "Number of attempted passes in open play",
  },
  op_passes_into_box_90: {
    label: "PIBp90",
    elongated: "OP Passes Into Box p90",
    explanation:
      "Successful passes into the box from outside the box (open play)",
  },
  op_xa_90: {
    label: "xAp90",
    elongated: "OP xA p90",
    explanation: "xG assisted from open play",
  },
  op_xgbuildup_90: {
    label: "xGBp90",
    elongated: "OP xG Buildup p90",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession. The buildup version omits xG and xG Assisted to focus on possession work prior to the end of the chain",
  },
  op_xgchain_90: {
    label: "xGChainp90",
    elongated: "OP xGChain p90",
    explanation:
      "A model that attributes the xG value of the final shot to all players involved in the entire possession",
  },
  obv_90: {
    label: "OBVp90",
    elongated: "OBV p90",
    explanation: "On Ball Value Added (net) total (all event types)",
  },
  obv_defensive_action_90: {
    label: "OBVDAp90",
    elongated: "OBV Defensive Action p90",
    explanation: "On Ball Value Added (net) from Defensive Actions",
  },
  obv_dribble_carry_90: {
    label: "OBVDCp90",
    elongated: "OBV Dribble Carry p90",
    explanation: "On Ball Value Added (net) from Dribbles and Carries",
  },
  obv_gk_90: {
    label: "OBVGKp90",
    elongated: "OBV GK p90",
    explanation: "On Ball Value Added (net) Goalkeeper",
  },
  obv_pass_90: {
    label: "OBVPp90",
    elongated: "OBV Pass p90",
    explanation: "On Ball Value Added (net) from Passes",
  },
  obv_shot_90: {
    label: "OBVShp90",
    elongated: "OBV Shot p90",
    explanation: "On Ball Value Added (net) from Shots",
  },
  p_obv_90: {
    label: "Pass OBV p90",
    elongated: "Pass OBV per 90",
    explanation: "On Ball Value from passing per 90",
  },
  padj_pressures_90: {
    label: "pAdjPrp90",
    elongated: "pAdj Pressure p90",
    explanation: "Possession adjusted pressures",
  },
  passes_into_box_90: {
    label: "PiBp90",
    elongated: "Passes into Box p90",
    explanation: "Successful passes into box from outside the box",
  },
  passes_pressed_ratio: {
    label: "PassPrssd%",
    elongated: "Passes Pressed Ratio",
    explanation:
      "Proportion of passes that were made while under opponent pressure",
  },
  passing_ratio: {
    label: "Pass%",
    elongated: "Passing Ratio",
    explanation: "Percentage of all passes attempted that were completed",
  },
  possessions_to_zone_3: {
    label: "",
    elongated: "",
    explanation: "",
  },
  pressured_passing_ratio: {
    label: "PrssdPass%",
    elongated: "Pressured Passing Ratio",
    explanation: "Proportion of pressured passes that were completed",
  },
  pressures_90: {
    label: "Prsp90",
    elongated: "Pressures p90",
    explanation: "The number of times a player pressures an opposition player",
  },
  red_cards_90: {
    label: "RCp90",
    elongated: "Red Cards per 90",
    explanation: "How many red cards the player obtains per 90",
  },
  second_yellow_cards_90: {
    label: "YCp90",
    elongated: "Yellow Cards per 90",
    explanation: "How many times a player obtains a second yellow card per 90",
  },
  short_pass_completion: {
    label: "Short Pass Comp. Rate",
    elongated: "Short Pass Completion Rate",
    explanation:
      "The completion rate of passes of short length (less than 15m)",
  },
  sp_passes_into_box_90: {
    label: "SPPiBp90",
    elongated: "Set Piece Passes into Box p90",
    explanation:
      "A pass into the box from a set piece situation (indirect free kick, corner, throw in)",
  },
  tackles_90: {
    label: "Tckls p90",
    elongated: "Tackles p90",
    explanation: "Successful challenges made",
  },
  tackles_interceptions: {
    label: "T+Ip90",
    elongated: "Tackles + Interceptions p90",
    explanation: "Combination of tackles and interceptions per 90",
  },
  shot_touch_ratio: {
    label: "ShTch%",
    elongated: "Shot Touch Ratio",
    explanation:
      "The amount of shots a player takes as a proportion of their touches of the ball",
  },
  tackles_and_interceptions_90: {
    label: "T+Ip90",
    elongated: "Tackles + Interceptions p90",
    explanation: "Combination of tackles and interceptions",
  },
  padj_tackles_and_interceptions_90: {
    label: "padjT+Ip90",
    elongated: "Adj. Tackles + Interceptions p90",
    explanation:
      "Number of tackles and interceptions adjusted proportionally to the possession volume of a team",
  },
  through_balls_90: {
    label: "TBp90",
    elongated: "Through Balls p90",
    explanation:
      "A completed pass splitting the defence for a teammate to runonto",
  },
  total_dribbles_90: {
    label: "TDp90",
    elongated: "Total Dribbles p90",
    explanation:
      "An attempt by the player to beat the opponent with the ball at their feet",
  },
  touches_inside_box_90: {
    label: "TchBxp90",
    elongated: "Touches In Box p90",
    explanation: "Successful footed touches inside the box (including shots)",
  },
  turnovers_90: {
    label: "TOp90",
    elongated: "Turnovers p90",
    explanation:
      "How often a player loses the ball via a miscontrol or a failed dribble",
  },
  win_rate: {
    label: "Tckl Win Rate",
    elongated: "Tackle Win Rate",
    explanation: "Tackle success rate over the total number of tackles",
  },
  tss_crosses_into_box_pg: {
    label: "Box crosses",
    elongated: "Crosses into the box",
    explanation: "Number of successful crosses into the box",
  },
  tss_defensive_distance: {
    label: "Defensive Distance",
    elongated: "Defensive Distance",
    explanation:
      "The average distance from a teams own goal from which it makesdefensive actions",
  },
  tss_directness: {
    label: "Directness",
    elongated: "Directness",
    explanation:
      "A ratio of the distance towards goal from the start of apossession that ended in a shot, divided by the total distancetravelled in buildup to the shot",
  },
  tss_fhalf_pressures_ratio: {
    label: "F2 Pressure %",
    elongated: "F2 Pressure Percentage",
    explanation:
      "How many pressures are exerted in the opposition (final) half of the pitch",
  },
  tss_fhalf_counterpressures_ratio: {
    label: "F2 Counterpressure %",
    elongated: "F2 Counterpressure Percentage",
    explanation:
      "How many counterpressures are exerted in the opposition (final)half of the pitch",
  },
  tss_np_xg_against: {
    label: "Non-penalty xG Against",
    elongated: "Non-penalty xG Conceded",
    explanation:
      "Non-penalty Expected goal value of shots from open play by opposition",
  },
  tss_np_xg_diff: {
    label: "Non-penalty xG Diff",
    elongated: "Non-penalty xG For minus xG Against",
    explanation: "Non-penalty xG minus xG conceded (not including penalties)",
  },
  tss_np_xg_for: {
    label: "Non-penalty xG For",
    elongated: "Non Penalty xG For",
    explanation: "Non-penalty expected goal value of shots from open play",
  },
  tss_np_xg_pg: {
    label: "Non-penalty xG For",
    elongated: "Non-penalty xG For",
    explanation: "Non-penalty expected goal value of shots from open play",
  },
  tss_np_xgd_pg: {
    label: "xG Diff",
    elongated: "xG Diff",
    explanation: "Expected goal difference from open play",
  },
  tss_np_xg_conceded_pg: {
    label: "xG Against",
    elongated: "xG Against",
    explanation:
      "Cumulative expected goal value of all non-penalty shotsconceded",
  },
  tss_ns_xg_against: {
    label: "Non-shot xG Against",
    elongated: "Non-shot xG Conceded",
    explanation:
      "Non-shot Expected goal value of shots from open play by opposition",
  },
  tss_ns_xg_diff: {
    label: "Non-shot xG Diff",
    elongated: "Non-shot xG For minus xG Against",
    explanation: "Non-shot xG minus xG conceded (not including penalties)",
  },
  tss_ns_xg_for: {
    label: "Non-shot xG For",
    elongated: "Non-shot xG For",
    explanation: "Non-shot expected goal value of shots from open play",
  },
  tss_obv_dribble_carry_pg: {
    label: "OBV Dribble Carry",
    elongated: "OBV from dribbles and carries",
    explanation: "On Ball Value added (net) from dribbles and carries",
  },
  tss_obv_pass_pg: {
    label: "OBV Pass",
    elongated: "OBV from passes",
    explanation: "On Ball Value added (net) from passes",
  },
  tss_obv_pass_conceded_pg: {
    label: "Opposition OBV Pass",
    elongated: "Opposition OBV from passes",
    explanation: "Opposition On Ball Value added (net) from passes",
  },
  tss_obv_dribble_carry_conceded_pg: {
    label: "Opposition OBV Dribble Carry",
    elongated: "Opposition OBV from dribbles and carries",
    explanation:
      "Opposition On Ball Value added (net) from dribbles and carries",
  },
  tss_pace_towards_goal: {
    label: "Pace of Play",
    elongated: "Pace of Play",
    explanation:
      "The average speed of buildup for possessions that end in shots,from the start of possession to shot (m/s)",
  },
  tss_passing_ratio: {
    label: "Pass Completion Rate",
    elongated: "Rate at which passes were completed",
    explanation: "Passing completion rate",
  },
  tss_points_per_game: {
    label: "Points per Game",
    elongated: "Points per Game",
    explanation: "Points per Game",
  },
  tss_possession: {
    label: "Possesion",
    elongated: "Possession",
    explanation:
      "Standard metric for how much possession a team averages permatch",
  },
  tss_ppda: {
    label: "PPDA",
    elongated: "PPDA",
    explanation:
      "How many passes does a team allow the opponent before makinga defensive action (tackle, interception, foul). An indicator ofpressing intensity this metric is defined in attacking areas ie 40%of the length of the pitch away from a team's own goal andforwards",
  },
  tss_red_cards_pg: {
    label: "Red Cards",
    elongated: "How many red cards the team obtains",
    explanation: "How many red cards the team obtains",
  },
  tss_yellow_cards_pg: {
    label: "Yellow Cards",
    elongated: "How many yellow cards the team obtains",
    explanation: "How many yellow cards the team obtains",
  },

  // POSSESSION KPIS
  //    on ball
  for_avg_time_own_to_attacking_pctile: {
    label: "Avg Time Own to F3",
    elongated: "Avg Time Own to F3",
    explanation:
      "Average time an own third possession advances to the final third",
  },
  for_avg_time_middle_to_attacking_pctile: {
    label: "Avg Time Middle to F3",
    elongated: "Avg Time Middle to F3",
    explanation:
      "Average time a middle third possession advances to the final third",
  },
  for_shot_rate_final_third_pctile: {
    label: "Shot Rate",
    elongated: "F3 Shot Rate",
    explanation: "Percentage of F3 possessions that end in a shot",
  },
  for_end_final_third_rate_pctile: {
    label: "% of F3 Possessions",
    elongated: "Percentage of F3 Possessions",
    explanation: "Percentage of final third possessions",
  },

  //    off ball
  for_perc_poss_attacking_third_pctile: {
    label: "Opp. Own Third Turnover Rate",
    elongated: "Opp. Own Third Turnover Rate",
    explanation: "Perentage of possessions that start in the final third",
  },
  against_avg_time_own_to_attacking_pctile: {
    label: " Opp. Avg Time Own to F3",
    elongated: "Opp. Avg Time Own to F3",
    explanation:
      "Average time opponent's own third possession advances to the final third",
  },
  against_avg_time_middle_to_attacking_pctile: {
    label: "Opp. Avg Time Middle to F3",
    elongated: "Avg Time Opp. Middle to F3",
    explanation:
      "Average time opponent's middle third possession advances to the final third",
  },
  against_shot_rate_final_third_pctile: {
    label: "Opp. Shot Rate",
    elongated: "Opp. F3 Shot Rate",
    explanation:
      "Percentage of opponent's final third possessions that end in a shot",
  },
  against_end_final_third_rate_pctile: {
    label: "Opp. % of F3 Possessions",
    elongated: "Opp. Percentage of F3 Possessions",
    explanation: "Opp. percentage of final third possessions",
  },
  against_perc_poss_attacking_third_pctile: {
    label: "F3 Turnovers",
    elongated: "F3 Turnovers",
    explanation:
      "Percentage of opp. possessions that are turned over in own third",
  },

  // TEAM SEASON STATS PERCENTILES
  counter_attacking_shots_pg_percentile: {
    label: "Counter Attacking Shots",
    elongated: "Counter Attacking Shots",
    explanation:
      "Shots generated within 15 seconds of a possession that originates from a team's own half",
  },
  directness_percentile: {
    label: "Directness",
    elongated: "Directness",
    explanation:
      "Distance toward goal vs distance travelled in buildup to shot",
  },
  np_xg_pg_percentile: {
    label: "xG per Game",
    elongated: "xG per Game",
    explanation: "Cumulative expected goal value of all non-penalty shots",
  },
  aggression_percentile: {
    label: "Aggression",
    elongated: "Aggression",
    explanation:
      "Proportion of opponent's pass receipts that are tackled, fouled or pressured within 2 seconds",
  },
};

export const STAT_INFO: StatInfo = {
  ...THREE_SIXTY_STAT_INFO,
  ...NON_THREE_SIXTY_STAT_INFO,
  ...PFF_STAT_INFO,
};

// CLUBS: names must match the StatsBomb names
export const CHICAGO_FIRE = "Chicago Fire";
export const CHICAGO_FIRE_2 = "Chicago Fire II";
export const LUGANO = "Lugano";

// SUPERUSER
export const SUPERUSER = "*";
export const VISAS_ROLE = "visas";

// LEAGUES
export const MLS = "MLS";
export const MLS_NEXT = "MLS_NEXT";
export const MLS_NEXT_PRO = "MLS_NEXT_PRO";
export const SSL = "SSL";

// TEAMS
export const ACADEMY_TEAM = "academy";
export const FIRST_TEAM = "first_team";
export const SECOND_TEAM = "second_team";

// ROLES
export const ANALYTICS_ROLE = "analytics";
export const COACHING_ROLE = "coaching";
export const OPERATIONS_ROLE = "operations";
export const SCOUTING_ROLE = "scouting";
export const EQUIPMENT_ROLE = "equipment";

// PERMISSIONS
export const ANALYTICS_PERMISSION = "analytics";
export const COACHES_PERMISSION = "coaches";
export const FOLDERS_PERMISSION = "folders";
export const LISTS_PERMISSION = "lists";
export const NOTES_PERMISSION = "notes";
export const NOTIFICATIONS_PERMISSION = "notifications";
export const PERFORMANCE_PERMISSION = "performance";
export const PLAYERS_PERMISSION = "players";
export const REPORTS_PERMISSION = "reports";
export const SHARES_PERMISSION = "shares";
export const USERS_PERMISSION = "users";

export const TEAM_NAME_ABBRS: { [key: string]: string } = {
  // MLS
  "Atlanta United": "ATL",
  "Austin FC": "ATX",
  Cincinnati: "CIN",
  "CF Montreal": "MTL",
  Charlotte: "CLT",
  "Columbus Crew": "CLB",
  "Chicago Fire": "CHI",
  "Colorado Rapids": "COL",
  "DC United": "DC",
  "Houston Dynamo": "HOU",
  "Inter Miami": "MIA",
  "FC Dallas": "DAL",
  LAFC: "LAFC",
  "LA Galaxy": "LA",
  "Minnesota United": "MIN",
  "Nashville SC": "NSH",
  "New England Revolution": "NE",
  "New York City FC": "NYCFC",
  "New York Red Bulls": "NYRB",
  "Orlando City": "ORL",
  "Philadelphia Union": "PHI",
  "Portland Timbers": "POR",
  "Real Salt Lake": "RSL",
  "San Jose Earthquakes": "SJ",
  "Seattle Sounders": "SEA",
  "Sporting Kansas City": "SKC",
  "St. Louis City": "STL",
  "Toronto FC": "TOR",
  "Vancouver Whitecaps": "VAN",

  // MLS 2
  "Atlanta United II": "ATL",
  "Austin II": "ATX",
  "Chicago Fire II": "CHI",
  "Columbus Crew II": "CLB",
  "Colorado Rapids II": "COL",
  "Crown Legacy": "CL",
  "FC Cincinnati II": "CIN",
  "Houston Dynamo FC II": "HOU",
  "Huntsville City": "HUN",
  "Inter Miami II": "MIA",
  "LA Galaxy II": "LA",
  "Los Angeles II": "LAFC",
  "Minnesota United II": "MIN",
  "New England II": "NE",
  "New York City II": "NYCFC",
  "New York RB II": "NYRB",
  "North Texas": "NT",
  "Orlando City B": "ORL",
  "Philadelphia Union II": "PHI",
  "Portland Timbers II": "POR",
  "Real Monarchs": "RM",
  "Rocheser New York": "RNY",
  "San Jose Earthquakes II": "SJ",
  "St. Louis City II": "STL",
  "Sporting KC II": "SKC",
  "Swope Park Rangers": "SKC",
  "Tacoma Defiance": "TAC",
  "Toronto II": "TOR",
  "Vancouver Whitecaps II": "VAN",

  // SSL
  Basel: "BSL",
  "BSC Young Boys": "YB",
  "FC Zürich": "ZUR",
  "FC Stade Lausanne-Ouchy": "FCSLO",
  "FC Lausanne Sport": "FCLS",
  Grasshopper: "GRS",
  Lugano: "LGO",
  Luzern: "LZN",
  Servette: "SRV",
  Sion: "SION",
  "St. Gallen": "SG",
  Winterthur: "WTR",
  "Yverdon Sport FC": "YSFC",
};

export const VALUE_TYPE_PERCENTILES = "percentiles";
export const VALUE_TYPE_SEASON_RANK = "season_rank";
export const VALUE_TYPE_TEAM_RANK = "team_rank";
export const VALUE_TYPE_VALUE = "value";

export const SCOUTING_REPORT_GROUP_MLS = "MLS";
export const SCOUTING_REPORT_GROUP_USL = "USL";
export const SCOUTING_REPORT_GROUP_CANADA = "CANADA";
export const SCOUTING_REPORT_GROUP_MLS_NEXT_PRO = "MLS NEXT PRO";
export const SCOUTING_REPORT_GROUP_COLLEGE = "COLLEGE";
export const SCOUTING_REPORT_GROUP_ACADEMY = "ACADEMY";
export const SCOUTING_REPORT_GROUP_UNSIGNED = "UNSIGNED";
export const SCOUTING_REPORT_GROUP_NONE = "";

export const ACADEMY_AGE_GROUP_U11 = "u11";
export const ACADEMY_AGE_GROUPINGS = [
  SCOUTING_REPORT_GROUP_NONE,
  ACADEMY_AGE_GROUP_U11,
  "u12",
  "u13",
  "u14",
  "u15",
  "u16",
  "u17",
  "u18",
  "u19",
];

export const GROUPINGS_FIRST_DIVISION = [
  SCOUTING_REPORT_GROUP_MLS,
  "US MNT",
  "CA MNT",
  "AMERICANS ABROAD",
];

export const GROUPINGS_LOWER_DIVISION = [
  SCOUTING_REPORT_GROUP_USL,
  SCOUTING_REPORT_GROUP_CANADA,
  SCOUTING_REPORT_GROUP_MLS_NEXT_PRO,
  "US U20",
  "CA U20",
];

export const SCOUTING_REPORT_GROUPINGS = [
  ...GROUPINGS_FIRST_DIVISION,
  ...GROUPINGS_LOWER_DIVISION,
  SCOUTING_REPORT_GROUP_COLLEGE,
  SCOUTING_REPORT_GROUP_ACADEMY,
  SCOUTING_REPORT_GROUP_UNSIGNED,
  // SCOUTING_REPORT_GROUPINGS_NONE,
];

export const ACADEMY_SCOUTING_ACADEMY_PLAYER_RATING_LABELS = [
  "No",
  "Track",
  "Trial",
  "Sign",
];

export const SCOUTING_MLS_PLAYER_RATING_LABELS = [
  "MLS Best XI",
  "MLS DP",
  "MLS 4-10",
  "MLS 11-16",
  "MLS 17-23",
  "MLS 24-30",
];
export const SCOUTING_USL_CANADA_PLAYER_RATING_LABELS = [
  // USL, Canada
  "USL Starter",
  "Canada Starter",
];
export const SCOUTING_MLSNP_PLAYER_RATING_LABELS = [
  // MLS NP
  "MLSNP BP",
  "MLSNP Starter",
  "MLSNP Roster",
];
export const SCOUTING_COLLEGE_PLAYER_RATING_LABELS = [
  // College
  "College All-American",
  "College All-Conference",
  "College Starter",
];
export const SCOUTING_ACADEMY_PLAYER_RATING_LABELS = [
  // Academy
  "Youth USMNT",
  "MLS Academy Starter",
  "Academy Starter",
];

export const SCOUTING_PLAYER_RATING_LABELS = [
  ...SCOUTING_MLSNP_PLAYER_RATING_LABELS,
  ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
  ...SCOUTING_MLSNP_PLAYER_RATING_LABELS,
  ...SCOUTING_COLLEGE_PLAYER_RATING_LABELS,
  ...SCOUTING_ACADEMY_PLAYER_RATING_LABELS,
];

export const SCOUTING_POSITIONS = [
  "1 (keeper)",
  "2 (right outside back)",
  "3 (left outside back)",
  "4 (right center back)",
  "5 (left center back)",
  "6 (defensive center midfielder)",
  "7 (right winger)",
  "8 (right center midfielder)",
  "9 (striker)",
  "10 (attacking midfielder)",
  "11 (left winger)",
];

export const SCHOOLS = [];

export const EMPTY_FIELD = "N/A";

// SEARCH PARAMS KEYS
export const SEARCH_PARAMS_DEPTH_CHART_ID = "depth_chart_id";
export const SEARCH_PARAMS_LIST_ID = "list_id";
export const SEARCH_PARAMS_NOTE_ID = "note_id";
export const SEARCH_PARAMS_PLAYER_ID = "player_id";
export const SEARCH_PARAMS_REPORT_ID = "report_id";

// MLS Salary related stuff
// https://www.mlssoccer.com/about/roster-rules-and-regulations
export const SALARY_DP_CATEGORY = 1683750;
export const SALARY_TAM_CATEGORY = 683750;
export const SALARY_SUPPLEMENTAL = 100000;
export const SALARY_SUPPLEMENTAL_RESERVE = 71401;

export const MLS_SEASON_START = "2024-02-25";

export const MLSPA_POSITIONS = {
  Goalkeeper: { id: 0, label: "GK", value: 0 },
  Defender: { id: 1, label: "DF", value: 0 },
  "Right-back": { id: 2, label: "RB", value: 0 },
  "Left-back": { id: 3, label: "LB", value: 0 },
  "Center-back": { id: 4, label: "CB", value: 0 },
  "Right Wing": { id: 5, label: "RW", value: 0 },
  "Left Wing": { id: 6, label: "LW", value: 0 },
  Midfielder: { id: 7, label: "MF", value: 0 },
  "Right Midfield": { id: 8, label: "RM", value: 0 },
  "Left Midfield": { id: 9, label: "LM", value: 0 },
  "Central Midfield": { id: 10, label: "CM", value: 0 },
  "Defensive Midfield": { id: 11, label: "DM", value: 0 },
  "Attacking Midfield": { id: 12, label: "AM", value: 0 },
  Forward: { id: 13, label: "F", value: 0 },
  "Center Forward": { id: 14, label: "CF", value: 0 },
  null: { id: 15, label: EMPTY_FIELD, value: 0 },
};

export type SALARY_BY_POSITION_TYPE = {
  [position: string]: { id: number; label: string; value: number };
};

export interface PHASE_MAP {
  [name: string]: string;
}

export const PHASE_ABBRS: PHASE_MAP = {
  DEFENDING: "DFND",
  FINISHING: "FNSH",
  POSSESSION: "POSS",
  PROGRESSION: "PROG",
  PHYSICAL: "PHYS",
};

// https://teamcolorcodes.com/
export const TEAM_COLOR_MAP: { [key: string]: string } = {
  // MLS
  "Atlanta United": "#80000a",
  "Austin FC": "#00b140",
  "CF Montreal": "#0033A1",
  Charlotte: "#1A85C8",
  "Chicago Fire": "#FF0000",
  Cincinnati: "#F05323",
  "Colorado Rapids": "#960A2C",
  "Columbus Crew": "#FEDD00",
  "DC United": "#231F20",
  "FC Dallas": "#E81F3E",
  "Houston Dynamo": "#FF6B00",
  "Inter Miami": "#f7b5cd",
  "LA Galaxy": "#00245D",
  LAFC: "#c39e6d",
  "Minnesota United": "#8CD2F4",
  "Nashville SC": "#ECE83A",
  "New England Revolution": "#0A2240",
  "New York City FC": "#6CACE4",
  "New York Red Bulls": "#ED1E36",
  "Orlando City": "#633492",
  "Philadelphia Union": "#071B2C",
  "Portland Timbers": "#00482B",
  "Real Salt Lake": "#B30838",
  "San Jose Earthquakes": "#0067B1",
  "Seattle Sounders": "#005595",
  "Sporting Kansas City": "#91B0D5",
  "St. Louis City": "#DD004A",
  "Toronto FC": "#B81137",
  "Vancouver Whitecaps": "#00245E",

  // SSL
  Basel: "#e40428",
  "BSC Young Boys": "#f9cc11",
  "FC Lausanne Sport": "#402180",
  "FC Zürich": "#0098c9",
  Grasshopper: "#0D374F",
  Lugano: "#2a2a2b",
  Luzern: "#0A0EC6",
  Servette: "#85142b",
  Sion: "#ee3124",
  "St. Gallen": "#2c8548",
  Thun: "#e2001a",
  Winterthur: "#1BABBC",
  "Yverdon Sport FC": "#1585EC",
  Xamax: "#ff0000",
};

export const positions = [
  {
    key: "goalkeeper",
    label: "Goalkeeper",
    position: "1",
  },
  {
    key: "fullback",
    label: "Fullback",
    position: "2/3",
  },
  {
    key: "centerback",
    label: "Centerback",
    position: "4/5",
  },
  {
    key: "center_defensive_midfielder",
    label: "Center Midfielder",
    position: "6",
  },
  {
    key: "center_midfielder",
    label: "Center Midfielder",
    position: "8",
  },
  {
    key: "center_attacking_midfielder",
    label: "Attacking Midfielder",
    position: "10",
  },
  {
    key: "winger",
    label: "Winger",
    position: "7/11",
  },
  {
    key: "forward",
    label: "Forward",
    position: "9",
  },
] as Position[];

export const playerProfiles = [
  {
    profile: "goalkeeper",
    label: "Goalkeeper",
    position: "1",
  },
  {
    profile: "wide_fullback",
    label: "L/R Wide Fullback",
    position: "2/3",
  },
  {
    profile: "inside_fullback",
    label: "L/R Inside Fullback",
    position: "2/3",
  },
  {
    profile: "centerback",
    label: "Centerback",
    position: "4/5",
  },
  {
    profile: "center_defensive_midfielder",
    label: "Center Midfielder",
    position: "6",
  },
  {
    profile: "center_midfielder",
    label: "L/R Center Midfielder",
    position: "8",
  },
  {
    profile: "center_attacking_midfielder",
    label: "Center Attacking Midfielder",
    position: "10",
  },
  {
    profile: "winger",
    label: "L/R Winger",
    position: "7/11",
  },
  {
    profile: "holdup_forward",
    label: "Hold-Up Center Forward",
    position: "9",
  },
  {
    profile: "penetrator_forward",
    label: "Penetrator Center Forward",
    position: "9",
  },
] as PlayerProfile[];

export interface ReportGroupData {
  group: string;
  average: number;
  filled: number;
  total: number;
  qualities: ReportQualityData[];
}

export interface ReportQualityData {
  abb: string;
  label: string;
  average: number;
  filled: number;
  total: number;
}
