import {
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartOptions,
  Colors,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";

import { Box } from "@mui/system";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Colors,
  Filler,
  Tooltip,
  Legend
);

export type PlayerMetricsRadarChartProps = {
  chartOptions: ChartOptions<"radar">;
  datasets: ChartDataset<"radar", number[]>[];
  statLabels: string[];
};

export const PlayerMetricsRadarChart = (
  props: PlayerMetricsRadarChartProps
) => {
  const data: ChartData<"radar", number[], unknown> = {
    labels: props.statLabels,
    datasets: props.datasets,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 10,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "inherit",
      }}
    >
      <Radar data={data} options={props.chartOptions} />
    </Box>
  );
};

export default PlayerMetricsRadarChart;
