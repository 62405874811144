import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  Menu,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { DepthChartPlayer } from "../../../api/types";
import { Draggable } from "@hello-pangea/dnd";
import ReactDOM from "react-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { SEARCH_PARAMS_PLAYER_ID } from "../../../constants";
import { useDeleteDepthChartPlayerMutation } from "../../../api/mutations";
import { useSearchParams } from "react-router-dom";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import rfdc from "rfdc";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";

const deepCopy = rfdc();

const PASTEL_RED = "#FF6961";
// const PASTEL_YELLOW = "#FFFF00";
// const PASTEL_BLUE = "#0000FF";

interface PitchPlayerDraggableListItemProps {
  depthChartId: number;
  position: string;
  player: DepthChartPlayer;
  index: number;
  editMode: boolean;
  height: number;
  includeBorder: boolean;
  updateDepthChartPlayer: (dcp: DepthChartPlayer) => void;
}

const portal: HTMLElement = document.createElement("div");
portal.classList.add("pitch-player-portal");

if (!document.body) {
  throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

export default function PitchPlayerDraggableListItem(
  props: PitchPlayerDraggableListItemProps
) {
  // CONTEXTS
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  // STATE
  const [colorMenuAnchorEl, setColorMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [colorMenuIsOpen, setColorMenuIsOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // MUTATIONS
  const deleteDepthChartPlayer = useDeleteDepthChartPlayerMutation();

  // FUNCTION
  const handleColorMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setColorMenuAnchorEl(event.currentTarget);
    setColorMenuIsOpen(true);
  };

  const handleColorSelect = (color: string | null) => {
    handleColorMenuClose();

    let updatedDepthChartPlayer = deepCopy(props.player);
    updatedDepthChartPlayer.color = color;

    props.updateDepthChartPlayer(updatedDepthChartPlayer);
  };

  const handleColorMenuClose = () => {
    setColorMenuAnchorEl(null);
    setColorMenuIsOpen(false);
  };

  return (
    <>
      {props.player.id && (
        <Box display={"flex"}>
          <Draggable
            draggableId={`depth-chart-draggable-${props.depthChartId}-${props.position}-${props.player.id}`}
            index={props.index}
            isDragDisabled={!props.editMode}
          >
            {(provided, snapshot) => {
              // To fixed a render issue when dragging items in list, we need to assign to item to a
              // portal -- another DOM node outside of the component tree -- to overcome this positioning bug.
              // Honestly, I don't entirely understand how this works but it worked :D
              //
              // https://github.com/vtereshyn/react-beautiful-dnd-ru/blob/master/docs/patterns/using-a-portal.md
              // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
              //

              const usePortal = snapshot.isDragging;

              const item = (
                <ListItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={[
                    snapshot.isDragging
                      ? {}
                      : {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0px 0px 0px 0px",
                          borderBottom: props.includeBorder
                            ? `1px solid ${theme.palette.divider}`
                            : "",
                          "&:hover": {
                            bgcolor: theme.palette.grey[200],
                          },
                        },
                    {
                      height: props.height,
                      backgroundColor: props.player.color
                        ? props.player.color
                        : "",
                    },
                  ]}
                  onClick={() => {
                    if (!props.editMode) {
                      searchParams.set(
                        SEARCH_PARAMS_PLAYER_ID,
                        String(props.player.player.id)
                      );
                      setSearchParams(searchParams);
                    }
                  }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    sx={{
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <ListItemAvatar sx={{ minWidth: props.editMode ? 40 : 50 }}>
                      <Avatar
                        src={props.player.player.image}
                        sx={{ height: 32, width: 32 }}
                      />
                    </ListItemAvatar>

                    <Box>
                      <Typography
                        variant="david"
                        title={props.player.player.name}
                      >
                        {props.player.player.name}
                      </Typography>
                    </Box>
                  </Box>

                  {props.editMode && (
                    <Box display={"flex"} alignItems={"center"}>
                      <ConfirmationDialog
                        key={`$-depth-chart-action-menuconfirmation-dialog`}
                        open={deleteModalOpen}
                        title={`Are you sure you want to delete "${props.player.player.name}"?`}
                        yesAction={() => {
                          if (props.player.id) {
                            deleteDepthChartPlayer.mutate({
                              depthChartPlayerName: props.player.player.name,
                              depthChartPlayerId: props.player.id,
                            });
                          }
                          setDeleteModalOpen(false);
                        }}
                        noAction={() => {
                          setDeleteModalOpen(false);
                        }}
                      />
                      <Box
                        onClick={() => {
                          setDeleteModalOpen(true);
                        }}
                        pl={1}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title="Remove" placement="top">
                          <RemoveCircleOutlineIcon
                            fontSize="small"
                            sx={{ height: 12, width: 16 }}
                          />
                        </Tooltip>
                      </Box>
                      <Box
                        onClick={handleColorMenuClick}
                        sx={{ cursor: "pointer", pl: "4px", pr: "4px" }}
                      >
                        <Tooltip title="Color" placement="top">
                          <ColorLensOutlinedIcon
                            fontSize="small"
                            sx={{ height: 16, width: 16 }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </ListItem>
              );

              if (!usePortal) {
                return item;
              }

              // if dragging, put item in portal
              return ReactDOM.createPortal(item, portal);
            }}
          </Draggable>
          <Menu
            id={`color-menu-player-${props.player.id}`}
            open={colorMenuIsOpen}
            anchorEl={colorMenuAnchorEl}
            onClose={handleColorMenuClose}
            anchorOrigin={{ horizontal: 30, vertical: -15 }}
            // removes paper shadow
            elevation={0}
            slotProps={{
              paper: {
                sx: { backgroundColor: "transparent" },
              },
            }}
          >
            <Box
              onClick={() => handleColorSelect(PASTEL_RED)}
              sx={{
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <CircleIcon sx={{ color: PASTEL_RED }} />
            </Box>
            <Box
              onClick={() => handleColorSelect(null)}
              sx={{
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <CircleOutlinedIcon />
            </Box>
          </Menu>
        </Box>
      )}
    </>
  );
}
