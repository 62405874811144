const PolarIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="32px"
    viewBox="0, 0, 200, 200"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    className="icon"
    {...props}
  >
    <path
      style={{
        opacity: 1,
      }}
      fill="currentColor"
      d="M 62.5,22.5 C 66.1568,27.8045 69.8234,33.1378 73.5,38.5C 99.7855,26.9069 125.452,28.0736 150.5,42C 150.833,42.3333 151.167,42.6667 151.5,43C 148.374,48.0848 145.374,53.2514 142.5,58.5C 158.198,70.2263 166.865,85.8929 168.5,105.5C 174.833,105.5 181.167,105.5 187.5,105.5C 187.661,138.2 173.661,162.534 145.5,178.5C 141.765,173.374 138.265,168.041 135,162.5C 115.454,171.121 96.2871,170.287 77.5,160C 76.4829,158.951 76.3162,157.784 77,156.5C 79.6339,152.065 82.1339,147.565 84.5,143C 75.0803,135.219 69.4137,125.219 67.5,113C 48.8363,112.5 30.1696,112.333 11.5,112.5C 11.9686,72.8732 28.9686,42.8732 62.5,22.5 Z M 59.5,33.5 C 61.0744,33.9371 62.2411,34.9371 63,36.5C 76.0403,59.415 89.207,82.2483 102.5,105C 74.502,105.5 46.502,105.667 18.5,105.5C 20.8357,74.8055 34.5023,50.8055 59.5,33.5 Z M 100.5,37.5 C 112.921,36.6258 124.921,38.4591 136.5,43C 138.223,43.5232 139.556,44.5232 140.5,46C 130.134,64.5652 119.634,83.0652 109,101.5C 98.545,82.7536 87.7117,64.2536 76.5,46C 84.1297,41.8474 92.1297,39.014 100.5,37.5 Z M 137.5,65.5 C 151.596,74.9811 159.596,88.3144 161.5,105.5C 146.163,105.667 130.83,105.5 115.5,105C 122.984,91.8596 130.318,78.6929 137.5,65.5 Z M 75.5,112.5 C 84.5062,112.334 93.5062,112.5 102.5,113C 97.9445,120.62 93.2778,128.12 88.5,135.5C 80.7989,129.767 76.4656,122.101 75.5,112.5 Z M 115.5,113.5 C 137.004,112.172 158.67,112.172 180.5,113.5C 177.854,136.809 167.021,155.143 148,168.5C 137.14,150.103 126.307,131.77 115.5,113.5 Z M 108.5,116.5 C 116.392,129.272 124.059,142.272 131.5,155.5C 116.329,163.301 100.996,163.467 85.5,156C 93.3938,142.946 101.06,129.779 108.5,116.5 Z"
    />
  </svg>
);
export default PolarIcon;
