import { IconButton, Link, Portal } from "@mui/material";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

import AlertTitle from "@mui/material/AlertTitle";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { forwardRef } from "react";
import md5 from "md5";
import { useThemeContext } from "../contexts/CustomThemeContext";

export interface CustomAlertProps {
  open: boolean;
  message: string;
  severity: string;
  hideAlert: () => void;
  link?: string;
  messageLink?: string;
  title?: string;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const autoHideDuration = 3000;

export default function CustomAlert(props: CustomAlertProps) {
  const { theme } = useThemeContext();

  // console.debug(`snackbar open: ${open}`)
  const title = props?.title
    ? props?.title?.toUpperCase()
    : props.severity?.toUpperCase();

  return (
    <Portal>
      <Snackbar
        autoHideDuration={autoHideDuration}
        key={md5(props.message || "")}
        open={props.open && !!props.message}
        onClose={props.hideAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={props.severity as AlertColor}
          action={[
            <IconButton
              key={`icon-button-close-${props.messageLink}`}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.hideAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>,
          ]}
        >
          <AlertTitle>{title}</AlertTitle>
          {props.message ? props.message.toLowerCase() : ""}
          {props?.link && props?.messageLink && (
            <Link
              href={props.link}
              underline="always"
              color={theme.palette.primary.contrastText}
            >
              {props.messageLink}
            </Link>
          )}
        </Alert>
      </Snackbar>
    </Portal>
  );
}
