import { SvgIcon } from "@mui/material";
import concacafIcon from "../assets/logos/concacaf.svg?react";
import confederationIcon from "../assets/icons/confederation.svg?react";
import fieldIcon from "../assets/icons/field.svg?react";
import globeIcon from "../assets/icons/globe.svg?react";
import midwestIcon from "../assets/icons/midwest.svg?react";
import norowsIcon from "../assets/icons/norows.svg?react";
import radarIcon from "../assets/icons/radar.svg?react";

export const ConcacafIcon = () => {
  return (
    <SvgIcon
      component={concacafIcon}
      inheritViewBox
      style={{ width: "3rem", height: "3rem" }}
    />
  );
};
export const ConfederationIcon = () => {
  return (
    <SvgIcon
      component={confederationIcon}
      inheritViewBox
      style={{ width: "3rem", height: "3rem" }}
    />
  );
};
export const FieldIcon = () => {
  return (
    <SvgIcon
      component={fieldIcon}
      inheritViewBox
      style={{
        // transform: "rotate(90deg)",
        transformBox: "fill-box",
        transformOrigin: "center",
      }}
    />
  );
};
export const GlobeIcon = () => {
  return (
    <SvgIcon
      component={globeIcon}
      inheritViewBox
      style={{ width: "2rem", height: "2rem" }}
    />
  );
};
export const MidwestIcon = () => {
  return (
    <SvgIcon
      component={midwestIcon}
      inheritViewBox
      style={{ width: "3rem", height: "3rem" }}
    />
  );
};
export const NoRowsFoundIcon = () => {
  return (
    <SvgIcon
      component={norowsIcon}
      inheritViewBox
      style={{ opacity: 0.26, width: "6rem", height: "6rem" }}
    />
  );
};
export const RadarIcon = () => {
  return <SvgIcon stroke="currentColor" component={radarIcon} inheritViewBox />;
};
