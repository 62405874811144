import { createContext, useContext, useState } from "react";

import CustomAlert from "../components/CustomAlert";

interface AlertContextInterface {
  showAlert: (options: AlertOptions) => void;
}

export const SEVERITY_ERROR = "error";
export const SEVERITY_INFO = "info";
export const SEVERITY_SUCCESS = "success";
export const SEVERITY_WARNING = "warning";

export const NO_DATA_FOUND_ALERT_MESSAGE = "no data found";

export interface AlertOptions {
  message: string;
  severity: string;
  link?: string;
  messageLink?: string;
  open?: boolean;
  title?: string;
}

const AlertContext = createContext<AlertContextInterface>({
  showAlert: () => {},
});

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<string>(SEVERITY_INFO);
  const [link, setLink] = useState<string | undefined>();
  const [messageLink, setMessageLink] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();

  const showAlert = (alertOptions: AlertOptions) => {
    setLink(alertOptions.link);
    setMessage(alertOptions.message);
    setMessageLink(alertOptions.messageLink);
    setSeverity(alertOptions.severity);
    setTitle(alertOptions.title);
    setOpen(true);
  };

  const hideAlert = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      <CustomAlert
        link={link}
        message={message}
        messageLink={messageLink}
        open={open}
        severity={severity}
        title={title}
        hideAlert={hideAlert}
      />

      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => useContext(AlertContext);
