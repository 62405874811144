import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import { Box } from "@mui/material";
import Players from "./Players";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

function Database() {
  // HOOKS

  // CONTEXT
  const { height } = useWindowSizeContext();

  return (
    <Box style={{ height: height - APPBAR_HEIGHT_AS_NUM - 5 }}>
      <Players />
    </Box>
  );
}

export default Database;
