import { Box, Tooltip, Typography } from "@mui/material";
import { useHomePageData } from "../../api/queries";
import { Line, Radar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { STAT_INFO } from "../../constants";
import { HomePageMatchDataMatch } from "../../api/types";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ordinalSuffixOf } from "../scouting/utils";
import LoadingSpinnerOverlay from "../../components/LoadingSpinnerOverlay";

const defaultRadarOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    r: {
      pointLabels: {
        font: {
          size: 12,
        },
      },
      min: 0,
      max: 100,
    },
  },
};

const defaultLineGraphOptions = {
  scales: {
    y1: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
    y2: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        font: {
          size: 16,
        },
      },
    },
    tooltop: {
      enabled: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "x" as const, // https://stackoverflow.com/a/71034769
  },
};

const OperationsPage = () => {
  // const leagueTableData = useLeagueTable().data;
  const homePageData = useHomePageData();

  // STATE
  const [radarWidth, setRadarWidth] = useState(window.innerWidth / 2.5);
  const [onBallRadarData, setOnBallRadarData] = useState<ChartData<
    "radar",
    number[],
    unknown
  > | null>(null);
  const [offBallRadarData, setOffBallRadarData] = useState<ChartData<
    "radar",
    number[],
    unknown
  > | null>(null);

  const [matchData, setMatchData] = useState<ChartData<
    "line",
    number[],
    unknown
  > | null>(null);
  const [matchDataLabels, setMatchDataLabels] = useState([]);
  const [onBallRadarChartOptions, setOnBallRadarChartOptions] =
    useState<ChartOptions<"radar">>(defaultRadarOptions);
  const [offBallRadarChartOptions, setOffBallRadarChartOptions] =
    useState<ChartOptions<"radar">>(defaultRadarOptions);
  const [lineGraphOptions, setLineGraphOptions] = useState<
    ChartOptions<"line">
  >(defaultLineGraphOptions);
  const [lineGraphGroup, setLineGraphGroup] = useState<string>("xG");

  useEffect(() => {
    if (homePageData.data) {
      // set on ball radar data
      if (homePageData.data["on_ball_data"]) {
        let onBallData = homePageData.data["on_ball_data"];

        // get labels
        let onBallLabels = Object.keys(onBallData).map((key) => {
          return STAT_INFO[key]["label"];
        });

        // set explanations
        const tooltipExplanations = Object.keys(onBallData).map((stat: any) => {
          return STAT_INFO[stat].explanation;
        });

        // set data
        setOnBallRadarData({
          labels: onBallLabels,
          datasets: [
            {
              label: "On Ball KPIs",
              data: Object.values(onBallData),
            },
          ],
        } as ChartData<"radar", number[], unknown>);

        // set options
        setOnBallRadarChartOptions({
          ...defaultRadarOptions,
          plugins: {
            legend: {
              labels: {
                // This more specific font property overrides the global property
                font: {
                  size: 16,
                },
              },
            },
            tooltip: {
              callbacks: {
                afterTitle: function (tooltipItem: any) {
                  return tooltipExplanations[tooltipItem[0].dataIndex];
                },
                label: function (context: any) {
                  let label = context.parsed.r || "";

                  if (label) {
                    return `${ordinalSuffixOf(
                      String(Math.round(label))
                    )} Percentile`;
                  }
                },
              },
            },
          },
        });
      }

      // set off ball radar data
      if (homePageData.data["off_ball_data"]) {
        let offBallData = homePageData.data["off_ball_data"];

        // get labels
        let offBallLabels = Object.keys(offBallData).map((key) => {
          return STAT_INFO[key]["label"];
        });

        // set explanations
        const tooltipExplanations = Object.keys(offBallData).map(
          (stat: any) => {
            return STAT_INFO[stat].explanation;
          }
        );

        // set data
        setOffBallRadarData({
          labels: offBallLabels,
          datasets: [
            {
              label: "Off Ball KPIs",
              data: Object.values(offBallData),
            },
          ],
        } as ChartData<"radar", number[], unknown>);

        // set options
        setOffBallRadarChartOptions({
          ...defaultRadarOptions,
          plugins: {
            legend: {
              labels: {
                // This more specific font property overrides the global property
                font: {
                  size: 16,
                },
              },
            },
            tooltip: {
              callbacks: {
                afterTitle: function (tooltipItem: any) {
                  return tooltipExplanations[tooltipItem[0].dataIndex];
                },
                label: function (context: any) {
                  let label = context.parsed.r || "";

                  if (label) {
                    return `${ordinalSuffixOf(
                      String(Math.round(label))
                    )} Percentile`;
                  }
                },
              },
            },
          },
        });
      }

      // match data
      if (homePageData.data["match_data"]) {
        let matchData = homePageData.data["match_data"];

        // get labels
        let matchDataLabels = matchData.map((m: HomePageMatchDataMatch) => {
          return m["opponent"];
        });
        setMatchDataLabels(matchDataLabels);

        // default datasets
        let label1 = "NP xG";
        let data1 = matchData.map((m: HomePageMatchDataMatch) => {
          return m["np_xg"];
        });

        let label2 = "NP xG Conceded";
        let data2 = matchData.map((m: HomePageMatchDataMatch) => {
          return m["np_xg_conceded"];
        });

        setMatchData({
          labels: matchDataLabels,
          datasets: [
            {
              label: label1,
              data: data1,
              yAxisID: "y1",
            },
            {
              label: label2,
              data: data2,
              yAxisID: "y2",
            },
          ],
        } as ChartData<"line", number[], unknown>);

        // set options
        setLineGraphOptions({
          ...defaultLineGraphOptions,
          scales: {
            y1: {
              type: "linear" as const,
              display: true,
              position: "left" as const,
              min: 0,
              max: 4,
            },
            y2: {
              type: "linear" as const,
              display: true,
              position: "right" as const,
              min: 0,
              max: 4,
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        });
      }
    }
  }, [homePageData.data]);

  useEffect(() => {
    if (homePageData.data && homePageData.data["match_data"]) {
      let matchData = homePageData.data["match_data"];

      let label1, data1, label2, data2;

      // set datasets depending on line graph group
      switch (lineGraphGroup) {
        // XG
        case "xG":
          label1 = "NP xG";
          data1 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["np_xg"] * 100) / 100;
          });

          label2 = "NP xG Conceded";
          data2 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["np_xg_conceded"] * 100) / 100;
          });
          setMatchData({
            labels: matchDataLabels,
            datasets: [
              {
                label: label1,
                data: data1,
                yAxisID: "y1",
              },
              {
                label: label2,
                data: data2,
                yAxisID: "y2",
              },
            ],
          } as ChartData<"line", number[], unknown>);
          setLineGraphOptions({
            ...defaultLineGraphOptions,
            scales: {
              y1: {
                type: "linear" as const,
                display: true,
                position: "left" as const,
                title: {
                  display: true,
                  text: "NP xG",
                  font: {
                    weight: 600,
                  },
                },
                min: 0,
                max: 4,
              },
              y2: {
                type: "linear" as const,
                display: true,
                position: "right" as const,
                title: {
                  display: true,
                  text: "NP xG Conceded",
                  font: {
                    weight: 600,
                  },
                },
                min: 0,
                max: 4,
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          });
          break;

        // FINAL THIRD
        case "final_third":
          label1 = "Advancement";
          data1 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["for_avg_time_own_to_attacking"] * 10) / 10;
          });

          label2 = "Opp. Advancement";
          data2 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["against_avg_time_own_to_attacking"] * 10) / 10;
          });
          setMatchData({
            labels: matchDataLabels,
            datasets: [
              {
                label: label1,
                data: data1,
                yAxisID: "y1",
              },
              {
                label: label2,
                data: data2,
                yAxisID: "y2",
              },
            ],
          } as ChartData<"line", number[], unknown>);
          setLineGraphOptions({
            ...defaultLineGraphOptions,
            scales: {
              y1: {
                type: "linear" as const,
                display: true,
                position: "left" as const,
                title: {
                  display: true,
                  text: "Advancement (seconds)",
                  font: {
                    weight: 600,
                  },
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + "s";
                  },
                },
                min: 0,
                max: 70,
              },
              y2: {
                type: "linear" as const,
                display: true,
                position: "right" as const,
                title: {
                  display: true,
                  text: "Advancement (seconds)",
                  font: {
                    weight: 600,
                  },
                },
                grid: {
                  drawOnChartArea: false,
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + "s";
                  },
                },
                min: 0,
                max: 70,
              },
            },
          });
          break;

        // SHOT RATE
        case "shot_rate":
          label1 = "Shot Rate";
          data1 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["for_shot_rate_final_third"] * 1000) / 10;
          });

          label2 = "Opp. Shot Rate";
          data2 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["against_shot_rate_final_third"] * 1000) / 10;
          });
          setMatchData({
            labels: matchDataLabels,
            datasets: [
              {
                label: label1,
                data: data1,
                yAxisID: "y1",
              },
              {
                label: label2,
                data: data2,
                yAxisID: "y2",
              },
            ],
          } as ChartData<"line", number[], unknown>);
          setLineGraphOptions({
            ...defaultLineGraphOptions,
            scales: {
              y1: {
                type: "linear" as const,
                display: true,
                position: "left" as const,
                title: {
                  display: true,
                  text: "Shot Rate %",
                  font: {
                    weight: 600,
                  },
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                min: 0,
                max: 100,
              },
              y2: {
                type: "linear" as const,
                display: true,
                position: "right" as const,
                title: {
                  display: true,
                  text: "Opp. Shot Rate %",
                  font: {
                    weight: 600,
                  },
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                min: 0,
                max: 100,
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          });
          break;

        // PPDA
        case "ppda":
          label1 = "PPDA";
          data1 = matchData.map((m: HomePageMatchDataMatch) => {
            return m["ppda"];
          });

          label2 = "% Possessions Starting in Final Third";
          data2 = matchData.map((m: HomePageMatchDataMatch) => {
            return Math.round(m["for_perc_poss_attacking_third"] * 1000) / 10;
          });
          setMatchData({
            labels: matchDataLabels,
            datasets: [
              {
                label: label1,
                data: data1,
                yAxisID: "y1",
              },
              {
                label: label2,
                data: data2,
                yAxisID: "y2",
              },
            ],
          } as ChartData<"line", number[], unknown>);
          setLineGraphOptions({
            ...defaultLineGraphOptions,
            scales: {
              y1: {
                type: "linear" as const,
                display: true,
                position: "left" as const,
                title: {
                  display: true,
                  text: "Passes per Defensive Action",
                  font: {
                    weight: 600,
                  },
                },
                // min: 0,
              },
              y2: {
                type: "linear" as const,
                display: true,
                position: "right" as const,
                title: {
                  display: true,
                  text: "% Possessions Starting in Final Third",
                  font: {
                    weight: 600,
                  },
                },
                grid: {
                  display: false,
                },
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                // min: 0,
              },
            },
          });
          break;
        default:
          label1 = "NP xG";
          data1 = matchData.map((m: HomePageMatchDataMatch) => {
            return m["np_xg"];
          });
          label2 = "NP xG Conceded";
          data2 = matchData.map((m: HomePageMatchDataMatch) => {
            return m["np_xg_conceded"];
          });
          setMatchData({
            labels: matchDataLabels,
            datasets: [
              {
                label: label1,
                data: data1,
                yAxisID: "y1",
              },
              {
                label: label2,
                data: data2,
                yAxisID: "y2",
              },
            ],
          } as ChartData<"line", number[], unknown>);
          break;
      }
    }
  }, [lineGraphGroup, homePageData.data, matchDataLabels]);

  useEffect(() => {
    const handleResize = () => {
      setRadarWidth(window.innerWidth / 2.5); // Example: Adjust width to half of the window size
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial size adjustment

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  return (
    <Box p={4}>
      {(homePageData.isFetching && <LoadingSpinnerOverlay />) || (
        <>
          {(onBallRadarData || offBallRadarData) && (
            <Box display={"flex"} justifyContent={"center"} pb={6}>
              <Typography
                variant="h5"
                fontWeight={500}
                sx={{ textDecoration: "underline" }}
              >
                SEASON PERFORMANCE
              </Typography>
            </Box>
          )}
          <Box display={"flex"} justifyContent={"space-evenly"}>
            {onBallRadarData && (
              <Box display={"flex"} width={radarWidth} height={500}>
                <Radar
                  data={onBallRadarData}
                  options={onBallRadarChartOptions}
                />
              </Box>
            )}
            {offBallRadarData && (
              <Box display={"flex"} width={radarWidth} height={500}>
                <Radar
                  data={offBallRadarData}
                  options={offBallRadarChartOptions}
                />
              </Box>
            )}
          </Box>
          <Box p={8}>
            {matchData && (
              <Box>
                {matchData && (
                  <Box display={"flex"} justifyContent={"center"} pb={8}>
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      sx={{ textDecoration: "underline" }}
                    >
                      MATCH METRICS
                    </Typography>
                  </Box>
                )}
                <Box display={"flex"} justifyContent={"center"} pb={2}>
                  <ToggleButtonGroup
                    value={lineGraphGroup}
                    exclusive
                    onChange={(_, value) => {
                      if (value !== null) {
                        setLineGraphGroup(value);
                      }
                    }}
                  >
                    <ToggleButton value="xG">
                      <Tooltip title="Expected Goals">
                        <Typography>xG</Typography>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="final_third">
                      <Tooltip title="Avg Time to get from Own to Final Third">
                        <Typography>Final Third</Typography>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="shot_rate">
                      <Tooltip title="% of Final Third Possessions that End in a Shot">
                        <Typography>Shot Rate</Typography>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="ppda">
                      <Tooltip title="PPDA and % of Possessions that Start in Final Third">
                        <Typography>PPDA</Typography>
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box display={"flex"} justifyContent={"center"} height={700}>
                  <Line data={matchData} options={lineGraphOptions} />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default OperationsPage;
