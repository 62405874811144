import { Dispatch, SetStateAction } from "react";

import AWS from "aws-sdk";
import { ObjectList } from "aws-sdk/clients/s3";
import { useAuth } from "../api/queries";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";

export const GET_S3_PLAYER_FILES = "get_player_files";
const S3_BUCKET = "player-info-files";
const SIGNED_URL_EXPIRE_SECONDS = 60 * 5;

export type UploadedFile = {
  name: string;
  success: boolean;
  error: any;
};

export const generateSignedUrl = (key: string) => {
  const s3 = new AWS.S3();
  return s3.getSignedUrl("getObject", {
    Bucket: S3_BUCKET,
    Key: key,
    Expires: SIGNED_URL_EXPIRE_SECONDS,
  });
};

export function useFetchFiles(
  prefix: string,
  setFileList: Dispatch<SetStateAction<ObjectList>>
) {
  const auth = useAuth();
  return useQuery(
    [GET_S3_PLAYER_FILES, { prefix: prefix }],
    async () => {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: S3_BUCKET,
          Prefix: prefix,
        };

        s3.listObjectsV2(
          params,
          (err: AWS.AWSError, data: AWS.S3.ListObjectsV2Output) => {
            if (err) {
              console.error(err, err.stack);
            } else {
              setFileList(data.Contents || []);
            }
          }
        );
      } catch (exception) {
        console.error(exception);
        /* handle the exception */
      }
    },
    {
      enabled:
        !!auth.data?.aws_access_key_id && !!auth.data?.aws_secret_access_key,
    }
  );
}

export const uploadFile = async (file: any, directory: string) => {
  let success = true;
  let error = null;

  try {
    // console.debug(file);
    const fileName = `${directory}/${file?.name || uuidv4()}`;
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: file,
    };

    // console.debug(params);

    // Use S3 ManagedUpload class as it supports multipart uploads
    const upload = new AWS.S3.ManagedUpload({
      params: params,
    });

    const promise = upload.promise();

    promise.then(
      function (data: any) {
        console.debug(`succesfully uploaded file: ${data}`);
      },
      function (err: any) {
        console.error(err);
        success = false;
        error = err;
      }
    );
  } catch (err) {
    console.error(err);
    success = false;
    error = err;
  }

  return { name: file.name, success: success, error: error };
};
