import { Box, List, Menu } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  LIST_ACTION_PLAYER_REMOVE,
  useUpdateListPlayerMutation,
} from "../api/mutations";

import ActionsMenuItem from "./ActionsMenuItem";
import { DepthChartSelectionDialog } from "./dialogs/DepthChartSelectionDialog";
import { ListSelectionDialog } from "./dialogs/ListSelectionDialog";
import MutatePlayerDialog from "./dialogs/MutatePlayerDialog";
import { Player } from "../api/types";
import { useListContext } from "../contexts/ListContext";
import { useMatchReportDialogContext } from "../contexts/MatchReportDialogContext";
import { usePlayerContext } from "../contexts/PlayerContext";
import { useUploadFileDialogContext } from "../contexts/UploadFileDialogContext";

export interface PlayerProfileActionsMenuProps {
  anchorEl: Element | null;
  open: boolean;
  // scoutingReportsDrawerOpen: boolean;
  handleActionMenuClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setCreateListDialogOpen: Dispatch<SetStateAction<boolean>>;
  setCreateNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  // setScoutingReportsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  // toggleScoutingReportsDrawer: (open: boolean) => Promise<void>;
}

const PlayerProfileActionsMenu = (props: PlayerProfileActionsMenuProps) => {
  // CONTEXTS
  const { playerInContext } = usePlayerContext();
  const { listInContext } = useListContext();
  const { handleCreateReportClick } = useMatchReportDialogContext();
  const { handleUploadFileDialogClick } = useUploadFileDialogContext();

  // STATES
  const [editPlayerDialogOpen, setEditPlayerDialogOpen] =
    useState<boolean>(false);
  const [playerIsInListContext, setPlayerIsInListContext] = useState(false);
  const [listSelectionDialogOpen, setListSelectionDialogOpen] = useState(false);
  const [depthChartSelectionDialogOpen, setDepthChartSelectionDialogOpen] =
    useState(false);

  // console.debug(playerInContext?);

  const competitionName = playerInContext?.competition_name
    ? playerInContext?.competition_name
    : "most recent competition";

  const seasonName = playerInContext?.season_name
    ? playerInContext?.season_name
    : "most recent season";

  // MUTATIONS
  const removePlayerFromList = useUpdateListPlayerMutation(
    LIST_ACTION_PLAYER_REMOVE
  );

  // EFFECTS
  useEffect(() => {
    // console.debug(listInContext?.id);
    // console.debug(playerInContext?.list_ids);
    if (playerInContext) {
      setPlayerIsInListContext(
        listInContext?.id !== undefined &&
          playerInContext?.list_ids?.includes(listInContext.id)
      );
    }
  }, [playerInContext, listInContext?.id, playerInContext?.list_ids]);

  const handleRemovePlayerFromListClick = (event: any, player: Player) => {
    // console.debug(player);
    // console.debug(listInContext);
    if (player) {
      props.handleActionMenuClose(event);
      removePlayerFromList.mutate({
        player: player,
        playerList: listInContext,
      });
      setPlayerIsInListContext(false);
    }
  };

  return (
    <Box key={`${playerInContext?.id}-list-box`}>
      <MutatePlayerDialog
        open={editPlayerDialogOpen}
        setOpen={setEditPlayerDialogOpen}
      />

      {playerInContext && !playerIsInListContext && (
        <ListSelectionDialog
          open={listSelectionDialogOpen}
          setOpen={setListSelectionDialogOpen}
          players={[playerInContext]}
        />
      )}

      <DepthChartSelectionDialog
        open={depthChartSelectionDialogOpen}
        setOpen={setDepthChartSelectionDialogOpen}
        player={playerInContext}
      />

      <Menu
        key={`${playerInContext?.id}-menu`}
        aria-labelledby="player-actions-menu"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleActionMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List key={`${playerInContext?.id}-menu-list`}>
          {!playerInContext?.tm_id && (
            <ActionsMenuItem
              uniqueKey={`${playerInContext?.id}-edit-player-info`}
              text={`Edit Player Info`}
              onClick={() => {
                setEditPlayerDialogOpen(true);
              }}
            />
          )}

          <ActionsMenuItem
            uniqueKey={`${playerInContext?.id}-add-to-depth-chart`}
            text={"Add to Depth Chart"}
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              setDepthChartSelectionDialogOpen(true);
            }}
          />

          {playerInContext && !playerIsInListContext && (
            <ActionsMenuItem
              uniqueKey={`${playerInContext.id}-add-to-list`}
              text={`Add to List (${competitionName} | ${seasonName})`}
              onClick={(event: any) => {
                setListSelectionDialogOpen(true);
              }}
            />
          )}

          <ActionsMenuItem
            uniqueKey={`${playerInContext?.id}-create-list`}
            text={`Create List (${competitionName} | ${seasonName})`}
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              props.setCreateListDialogOpen(true);
            }}
          />

          <ActionsMenuItem
            uniqueKey={`${playerInContext?.id}-create-note`}
            text="Create Note"
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              props.setCreateNoteModalOpen(true);
            }}
          />

          <ActionsMenuItem
            uniqueKey={`${playerInContext?.id}-create-match-report`}
            text="Create Match Report"
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              handleCreateReportClick(playerInContext);
            }}
          />

          <ActionsMenuItem
            uniqueKey={`${playerInContext?.id}-upload-file`}
            text="Upload File"
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              handleUploadFileDialogClick();
            }}
          />

          {playerInContext?.id && listInContext && playerIsInListContext && (
            <ActionsMenuItem
              uniqueKey={`${playerInContext?.id}-remove-from-list`}
              text={`Remove from ${listInContext.name} (${competitionName} | ${seasonName})`}
              onClick={(event: any) => {
                handleRemovePlayerFromListClick(event, playerInContext);
              }}
            />
          )}
        </List>
      </Menu>
    </Box>
  );
};

export default PlayerProfileActionsMenu;
