import { createContext, useContext, useEffect, useState } from "react";

import APIClient from "../api/client";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

interface APIContextInterface {
  apiClient: APIClient;
  authenticated: boolean;
}

const defaultState = {
  apiClient: new APIClient(),
  authenticated: false,
};

const APIContext = createContext<APIContextInterface>(defaultState);

export const APIProvider = ({ children }: { children: React.ReactNode }) => {
  const { authState } = useOktaAuth();
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const client = new APIClient();

  // Set Authorization header when AuthState becomes viable
  useEffect(() => {
    if (authState?.accessToken?.accessToken) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authState?.accessToken?.accessToken}`;
      axios.defaults.headers.common["apikey"] = import.meta.env.VITE_API_KEY;
      setAuthenticated(authState?.isAuthenticated || false);
    }
  }, [authState?.accessToken?.accessToken, authState?.isAuthenticated]);

  return (
    <APIContext.Provider
      value={{ apiClient: client, authenticated: authenticated }}
    >
      {children}
    </APIContext.Provider>
  );
};

export const useAPIContext = () => useContext(APIContext);
