import "../../contexts/AlertContext";

import { Box, DialogContent, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FileDropzone from "../FileDropzone";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";

export interface UploadDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function UploadFileDialog(props: UploadDialogProps) {
  const { theme } = useThemeContext();
  const { playerInContext } = usePlayerContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        minWidth: "80%",
      }}
    >
      <Dialog
        open={props.open}
        fullScreen={isScreenSmall}
        fullWidth
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <CloseButton position="absolute" onClick={() => props.setOpen(false)} />

        <DialogTitle justifyContent="center" alignItems="center" display="flex">
          Upload Files
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {playerInContext && <FileDropzone playerId={playerInContext.id} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
