import "./App.css";

import {
  AlertProvider,
  SEVERITY_ERROR,
  useAlertContext,
} from "./contexts/AlertContext";
import { Box, Skeleton } from "@mui/material";
import CustomAppBar, { APPBAR_HEIGHT } from "./app_bar/Toolbar";
import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import { Suspense, useEffect, useState } from "react";
import { isProduction, oktaConfig } from "./config";
import { useLocation, useNavigate } from "react-router-dom";

import { APIProvider } from "./contexts/APIContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import AppRoutes from "./AppRoutes";
import { AxiosError } from "axios";
import { ClubProvider } from "./contexts/ClubContext";
import { ConstantsProvider } from "./contexts/ConstantsContext";
import { CustomThemeProvider } from "./contexts/CustomThemeContext";
import { DepthChartProvider } from "./contexts/DepthChartContext";
import { DndProvider } from "react-dnd";
import { GroupProvider } from "./contexts/GroupContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import { HotkeysProvider } from "react-hotkeys-hook";
import { ListProvider } from "./contexts/ListContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import NotesDialog from "./components/dialogs/NotesDialog";
import { NotificationsProvider } from "./contexts/NotificationContext";
import OAuthSignInPage from "./pages/signin";
import { OktaAuthProvider } from "./contexts/OktaAuthContext";
import PlayerProfileDialog from "./components/dialogs/PlayerProfileDialog";
import { PlayerProvider } from "./contexts/PlayerContext";
import { ScoutingReportDialogProvider } from "./contexts/MatchReportDialogContext";
import { TeamProvider } from "./contexts/TeamContext";
import { UploadFileDialogProvider } from "./contexts/UploadFileDialogContext";
import { UserProvider } from "./contexts/UserContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { WindowSizeProvider } from "./contexts/WindowSizeContext";
import { checkRefreshToken } from "./utils/refreshToken";
import { useCacheBuster } from "react-cache-buster";

// console.log(oktaConfig);
const oktaAuth = new OktaAuth(oktaConfig);

const ERROR_BOUNDARY_HTTP_CODE = 500;
// console.debug(`ERROR_BOUNDARY_HTTP_CODE: ${ERROR_BOUNDARY_HTTP_CODE}`);

const title = isProduction ? "The Pond" : "The Pond (dev)";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: false,
    },
  },
});

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCacheStatus } = useCacheBuster();
  const { showAlert } = useAlertContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuthRequiredDialogOpen] = useState<boolean>(false);

  const isAuthenticated =
    oktaAuth.authStateManager.getAuthState()?.isAuthenticated;

  useEffect(() => {
    const defaultOptions: DefaultOptions = {
      queries: {
        enabled: isAuthenticated,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        onSettled: (data: any, error: any) => {
          if (error) {
            if (error instanceof AxiosError) {
              //   console.debug(error);

              if (Number(error?.response?.status) >= ERROR_BOUNDARY_HTTP_CODE) {
                showAlert({
                  message:
                    data?.alertMessage ||
                    `${error.message} | ${error.response?.data.detail}`,
                  severity: data?.alertSeverity || SEVERITY_ERROR,
                  title: data?.alertTitle || "an error occurred",
                });
              }
            } else {
              showAlert({
                message: data?.alertMessage || error.message,
                severity: data?.alertSeverity || SEVERITY_ERROR,
                title: data?.alertTitle || "an error occurred",
              });
            }
          }
        },
        useErrorBoundary: (error) => {
          const { authState, oktaAuth } = useOktaAuth();
          if (error instanceof AxiosError) {
            console.info(
              `error.response.status: ${error?.response?.status} | error.message: ${error.message}`
            );

            if (error?.response?.status === 401) {
              console.log("401 received");

              checkRefreshToken({ authState, oktaAuth }).then((refreshed) => {
                console.info(`token refreshed: ${refreshed}`);

                if (!refreshed) {
                  oktaAuth.signOut();
                }
              });
            }
          }

          return false;
        },
      },
      mutations: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSettled: (data: any, error: any, variables: any, context: any) => {
          // console.debug("onSettled");
          if (error && error.status >= 400) {
            showAlert({
              message: error?.response?.data?.detail
                ? error.response.data.detail
                : error.message,
              severity: SEVERITY_ERROR,
            });
          }
        },
        retry: false,
      },
    };
    queryClient.setDefaultOptions(defaultOptions);
  }, [showAlert, isAuthenticated]);

  // Check to see if there's a new version when the window is refocused
  window.addEventListener("focus", checkCacheStatus);
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", location));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await oktaAuth.signInWithRedirect();
    } else {
      // Ask the user to trigger the login process during token autoRenew process

      setAuthRequiredDialogOpen(true);
    }
  };

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <HelmetProvider>
        <Helmet title={title} />
      </HelmetProvider>

      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <OAuthSignInPage />
        <OktaAuthProvider>
          <HotkeysProvider>
            <APIProvider>
              <DndProvider debugMode={true} backend={HTML5Backend}>
                <UserProvider>
                  <ConstantsProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <WindowSizeProvider>
                        <WebSocketProvider>
                          <ClubProvider>
                            {" "}
                            {/* must be above of CustomThemeProvider */}
                            <CustomThemeProvider>
                              <TeamProvider>
                                {" "}
                                {/* must be above of PlayerProvider */}
                                <ListProvider>
                                  <DepthChartProvider>
                                    <PlayerProvider>
                                      <GroupProvider>
                                        <Suspense
                                          fallback={
                                            <Skeleton
                                              variant="rectangular"
                                              width="100%"
                                            />
                                          }
                                        >
                                          <NotificationsProvider>
                                            <AlertProvider>
                                              <ScoutingReportDialogProvider>
                                                <UploadFileDialogProvider>
                                                  <CustomAppBar />
                                                  <Box
                                                    sx={{
                                                      margin: 0,
                                                      border: 0,
                                                      padding: 0,
                                                      maxHeight: `calc(100vh - ${APPBAR_HEIGHT})`,
                                                    }}
                                                  >
                                                    <PlayerProfileDialog />
                                                    <NotesDialog />
                                                    <AppRoutes />
                                                  </Box>
                                                </UploadFileDialogProvider>
                                              </ScoutingReportDialogProvider>
                                            </AlertProvider>
                                          </NotificationsProvider>
                                        </Suspense>
                                      </GroupProvider>
                                    </PlayerProvider>
                                  </DepthChartProvider>
                                </ListProvider>{" "}
                              </TeamProvider>
                            </CustomThemeProvider>
                          </ClubProvider>{" "}
                        </WebSocketProvider>
                      </WindowSizeProvider>
                    </LocalizationProvider>
                  </ConstantsProvider>
                </UserProvider>
              </DndProvider>
            </APIProvider>
          </HotkeysProvider>
        </OktaAuthProvider>
      </Security>
    </QueryClientProvider>
  );
};

export default App;
