import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";

Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export type PlayerMetricBarChartProps = {
  chartOptions: ChartOptions<"bar">;
  datasets: ChartDataset<"bar", number[]>[];
  statLabels: string[];
};

const PlayerMetricsBarChart = (props: PlayerMetricBarChartProps) => {
  const data: ChartData<"bar", number[], unknown> = {
    labels: props.statLabels,
    datasets: props.datasets,
  };

  // console.debug(data);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Bar options={props.chartOptions} data={data} />
    </Box>
  );
};

export default PlayerMetricsBarChart;
